import React, {FC} from 'react'
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {useHistory} from "react-router-dom";

type Props = {
    redirect?: string;
}
const Error404: FC<Props> = ({redirect}) => {

    /**
     * The useHistory hook gives you access to the history instance that you may use to navigate.
     *  https://reactrouter.com/web/api/Hooks/usehistory
     */

    const history = useHistory()
    const redirectToDashboard = () => {

        history.push('/dashboard')
        window.location.reload();
    }

    return (
        <>
            <div className='d-flex flex-column flex-root mt-20'>
                <div
                className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                // style={{backgroundImage: `url(${bg_1})`}}
                >
                    <div className='d-flex flex-column flex-column-fluid text-center'>
                        <a href={`${(redirect) ? redirect : "/dashboard"}`}>
                            <img
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/Logo-WF_2.png')}
                                className='h-75px mb-5'
                            />
                        </a>
                        <div className='pt-lg-10 mb-10'>

                            <h1 className='fw-bolder fs-4x text-gray-700 mb-10 text-capitalize'>Page non trouvée</h1>

                            <div className='fw-bold fs-3 text-gray-400 mb-15'>
                                La page demandée n'a pas été trouvée ! <br />
                            </div>

                            <div className='text-center'>
                                <a onClick={redirectToDashboard} className='btn btn-lg btn-primary fw-bolder'>
                                    Aller à la page d'accueil
                                </a>
                            </div>
                        </div>
                        <div
                            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
                            style={{
                                backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sigma-1/18-dark.png')}')`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export {Error404}
