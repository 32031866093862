import React from "react";
import axios from "axios";
import ProductsData from "../ProductsData";
import PrivacyPolicyData from "../PrivacyPolicyData";
import {PropsPersonalInformation} from "./TypesGet";
import {API_URL, localStorageValue} from '../../all-pages'


/**
 * @param setId
 * @param setPersonalInformation
 * @param setProducts
 * @param setDataPrivacyPolicy
 * @constructor
 */
export default function GetUser(
    setId: any,
    setPersonalInformation: any,
    setProducts: any,
    setDataPrivacyPolicy: any,
) {

    const GET_USER = process.env.REACT_APP_GET_USER;

    axios({
            method: 'GET',
            url: `${API_URL}${GET_USER}`,
            headers: {
                'Authorization': `Bearer ${localStorageValue}`,
            },
        }
    )
        .then(function (response) {

            const DATA = response.data;

            const {id, firstname, lastname, email, images, reference, created_at, domainName} = DATA;

            window.localStorage.setItem("_clientCreationDate", created_at)

            // TopBar, Edit profile, Sign in method
            if (setId !== null) { setId(id); }

            //  TODO: Personal Information
            let personalInformation: PropsPersonalInformation;
            if (setPersonalInformation !== null) {

                personalInformation = {
                    id,
                    firstName: firstname,
                    lastName: lastname,
                    email,
                    image: '',
                    reference: reference,
                    domainName
                }
                if (images.length === 1) { personalInformation.image = images[0]['path']; }
                setPersonalInformation(personalInformation);
            }

        /**
         * PRIVACY POLICY policy
         */
            let privacyPolicyData = PrivacyPolicyData({data: [DATA.politique_conf]});
            if (setDataPrivacyPolicy !== null) { setDataPrivacyPolicy(privacyPolicyData); }

        /**
         * PRODUCTS
         */
            let productsData = [];
                productsData = ProductsData(
                    {products: [DATA.products], productsList: DATA.products_list},
                );

            console.log({productsData})

            if (setProducts !== null) { setProducts(productsData); }

            window.localStorage.setItem("_userId", id)
            // console.log('GetUser サクセス: 👍\n', DATA);
        })
        .catch(function (error) {

            console.log('GetUser エラー: 👎\n', error);
        });
}