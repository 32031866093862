import {Period} from './ReportProvider'
import {AdministrationData} from './types'
import SetupAxios from '../../../../setup/axios/SetupAxios'

const REPORT_ADMINISTRATION = process.env.REACT_APP_REPORT_ADMINISTRATION || 'administration-de-rapport'

export const fetchDashboardData = async (endpoint: string, period: Period) => {
  const parameter = period ? `?start=${period.start}&end=${period.end}` : ''
  const response = await SetupAxios.get(`${endpoint}${parameter}`)
  return response.data
}


export const fetchAdministrationData = async (): Promise<AdministrationData> => {
  const response = await SetupAxios.get(REPORT_ADMINISTRATION)

  const {data} = response.data
  return data
}