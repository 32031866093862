import React, {FC} from 'react'

type PropsInput = {
    formik: any,
    type: string
    name: string,
    placeholder: string,
}
const Input:FC<PropsInput> = ({formik, type, name, placeholder}) => {

    return (
        <>
            <input
                type={type}
                className='form-control form-control-sm form-control-solid mb-0'
                placeholder={placeholder}
                {...formik.getFieldProps({name})}
            />
            {formik.touched[name] && formik.errors[name] && (
                <div
                    className='fv-plugins-message-container my-1'>
                    <div className='fv-help-block'>
                        <span role='alert' className="text-danger p-2">{formik.errors[name]}</span>
                    </div>
                </div>
            )}
        </>
    )
}

export {Input}