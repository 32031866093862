import React from 'react'
import {PropsCheckBoxButton} from '../TypePrivacyPolicy'
import {Field} from 'formik'

const CheckBoxButton: React.FC<PropsCheckBoxButton> = ({name, values, toggle, text}) => {

    return (
        <div className="form-check form-switch form-check-custom form-check-solid">
            <Field className="form-check-input h-20px w-30px"
                   type="checkbox"
                   name={name}
                   id={name}
            />
            <label className={`form-check-label text-hover-gray-600 fs-7 fs-sm-5 p-2 ${values.toggle ? 'text-primary' : 'text-muted'}`}
                   htmlFor={name}>
                {text}
            </label>
        </div>
    )
}

export {CheckBoxButton}