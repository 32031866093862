import React, {FC} from 'react'
import CountUp from 'react-countup'

interface Card1Interface {
  title: string
  stats: number
  backgroundColor: string
  color: string
}

const Card1: FC<Card1Interface> = ({title, stats, backgroundColor, color}) => {



  return (
    <>
      <div className="card card-flush" style={{backgroundColor}}>
        <div className="card-body justify-content-center align-items-center p-5">
          <div className="d-flex flex-column">
            <span className="fw-bolder fs-4 text-uppercase text-center" style={{color, letterSpacing: 1.2}}>{title}</span>
            <span className="fw-bolder fs-2x text-center" style={{color}}>
              <CountUp end={stats === -1 ? 0 : stats} duration={1.5}/>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export {Card1}