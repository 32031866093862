import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {Tooltip, Whisper} from 'rsuite'
import {Ticket, Ticket_Status} from '../types'
import {truncateText} from '../../report/utils/functions'
import {DropDown} from '.'

interface TransformedTicket extends Omit<Ticket, 'status'> {
    status: Ticket_Status;
}

const TicketRow: FC<{ticket: TransformedTicket}> = ({ticket}) => {

    const ratingPercent = ticket.satisfaction ? ticket.satisfaction.rating * 100 / 5 : null

    return (
        <tr key={ticket.id} className={`bg-light-${ticket.count_message && 'danger'}`}>
            <td className="ps-4 text">{ticket.created_at}</td>
            <td>
                <Link to={`/sav/ticket/${ticket.id}`} className="text-dark fw-bolder text-hover-primary fs-7 fs-sm-6">
                    {truncateText(ticket.subject, 20)}
                </Link>
            </td>
            <td>{ticket.product}</td>
            <td>{ticket.assign}</td>
            <td><DropDown description={ticket.description} /></td>
            <td>
                <div className="d-flex justify-content-center align-content-center">
                {
                    ratingPercent && (
                        <div className="rating-label me-2 position-relative d-inline-block"
                             style={{width: '24px', height: '24px'}}
                        >
                            <i className="bi bi-star fs-1 position-absolute w-100" style={{top: 0, left: 0}} />
                            <i className="bi bi-star-fill fs-1 position-absolute overflow-hidden" style={{color: '#FFD700', width: `${ratingPercent}%`, top: 0, left: 0}} />
                        </div>
                    )
                }
                </div>
            </td>
            <td>
                <div className={`badge badge-light-${ticket.status.color} fs-8 fw-bolder`}>
                    {ticket.status.name}
                </div>
            </td>

            <td className="text-center">
                <Whisper placement="top" controlId="control-id-click" trigger="hover"
                         speaker={(<Tooltip>Aperçu</Tooltip>)}
                >
                    <div className={'position-relative'}
                         data-kt-menu-trigger="click"
                         data-kt-menu-attach="parent"
                    >
                        {
                            ticket.count_message > 0 && <span
                            className="fs-9 text-danger position-absolute translate-middle top-25 start-75 animation-blink">{ticket.count_message}</span>
                        }
                        <Link to={`/sav/ticket/${ticket.id}`}
                              className={`btn btn-sm btn-icon btn-active-light-${ticket.count_message ? 'danger' : 'success'}`}>
                            <i className={`bi bi-eye-fill fs-3 text-${ticket.count_message ? 'danger' : 'success'}`} />
                        </Link>
                    </div>

                </Whisper>
            </td>
        </tr>
    )
}

export {TicketRow}