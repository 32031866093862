import React, {forwardRef} from 'react'

const FormSelect = forwardRef<HTMLSelectElement, any>(({
                                                           label,
                                                           name,
                                                           touched,
                                                           error,
                                                           value,
                                                           options,
                                                           onChange,
                                                           disabled = false,
                                                       }, ref) => (
    <div className="d-flex flex-column fv-row fv-plugins-icon-container">
        <div className="w-100 d-flex justify-content-between">
            <label className="required fs-6 fw-semibold mb-2">{label}</label>
            {touched && error && (
                <div className="fv-plugins-message-container mb-1">
                    <span role="alert" className="text-danger p-2">{error}</span>
                </div>
            )}
        </div>
        <select ref={ref} name={name} value={value} onChange={onChange} className="form-select form-select-solid"
                disabled={disabled}>
            <option value=""/>
            {options.map((option: any) => (
                <option key={option.id} value={option.id}>{option.name}</option>
            ))}
        </select>
    </div>
))

export {FormSelect}