import axios from 'axios'
import {Dispatch, SetStateAction} from 'react'
import {API_URL, localStorageValue} from '../../all-pages'

type Props = {
    values: {message: string, products: string}
    setStatus: Dispatch<SetStateAction<string>>
    setDisable: Dispatch<SetStateAction<boolean>>
    setLoading: Dispatch<SetStateAction<boolean>>
    setTreaty1?: Dispatch<SetStateAction<boolean>>
}

function RequestAProduct({values, setStatus, setLoading, setDisable, setTreaty1}: Props) {

  const REQUEST_A_PRODUCT = process.env.REACT_APP_REQUEST_A_PRODUCT;

  axios({
    method: 'POST',
    url: `${API_URL}${REQUEST_A_PRODUCT}`,
    headers: {
      'Authorization': 'Bearer ' + localStorageValue,
    },
    data: values,
  })
    .then((response) => {

      setStatus("Votre demande de devis a bien été envoyée, Webforce vous répond dans les meilleurs délais.");
      setDisable(true);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        if (setTreaty1) setTreaty1(true);
      }, 300);

    })
    .catch((error) => {
      console.log(error)
    })
}

export {RequestAProduct}