import React, {FC} from 'react'
import {ReportProvider} from './context/ReportProvider'
import {ReportContent} from './ReportContent'

const Report: FC = () => {

  return (
    <ReportProvider>
      <ReportContent/>
    </ReportProvider>
  )
}

export {Report}