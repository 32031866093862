import React from "react";

/**
 * @param data
 * @return ['duration', 'ConPersonnell', 'AnonOrSuprem', 'ConListMarketing', 'AutMemNouvContac', 'AutMemImprContac']
 */
export default function PrivacyPolicyData({data}: { data: any[] }) {

    let value: any[] = [],
        fields = ['duration', 'AnonOrSuprem', 'ConPersonnell', 'ConListMarketing', 'AutMemNouvContac', 'AutMemImprContac'];

    for (const property in data[0]) {
        if (property) {

            if (fields.indexOf(property) !== -1) {

                value.push(data[0][property]);
            }
        }
    }

    return value;
}