import React, {Dispatch, SetStateAction} from "react";
import axios from "axios";
import {
    InterfaceTemplateCreate,
    InterfaceTemplateDefault
} from "../../../../products/mailing/components/campagnes/settings/contents/components/template/TypeTemplates";
import {API_URL, localStorageValue} from '../../../../all-pages'

interface Props {
    setDataTemplateGallery?: Dispatch<SetStateAction<InterfaceTemplateDefault[]>>;
    setDataTemplateCreate?: Dispatch<SetStateAction<InterfaceTemplateCreate[]>>;
}

export default function AllTemplates({setDataTemplateGallery, setDataTemplateCreate}: Props) {

    const ALL_TEMPLATES = process.env.REACT_APP_MAILING_ALL_TEMPLATES;

    axios({
        method: "GET",
        url: `${API_URL}${ALL_TEMPLATES}`,
        headers: {
            'Authorization': `Bearer ${localStorageValue}`,
        },
    })
        .then((response) => {

            const {templatesDefault, templatesCreate} = response.data;

            if (setDataTemplateGallery) setDataTemplateGallery(templatesDefault)
            if (setDataTemplateCreate) setDataTemplateCreate(templatesCreate)

        })
        .catch((error) => {
            console.log(error)
        })

}