import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import CountUp from 'react-countup'

interface Card1Interface {
    title: string
    stats: number | null
    svgIcon: {
        type: 'bootstrap' | 'duotune'
        path: string
    } | null
}

const Card2: FC<Card1Interface> = ({title, stats, svgIcon}) => {
    return (
        <>
            <div className="card h-lg-100" style={{backgroundColor: stats !== null ? '#fff' : '#8bcefb'}}>
                <div className={`card-body d-flex justify-content-${stats !== null ? 'between' : 'center'} align-items-center flex-column`}>
                    <div className="d-flex flex-column justify-content-center align-items-center my-7">
                        <span className="fw-semibold fs-3 text-uppercase fw-bolder mb-3"
                              style={{letterSpacing: 2, color: stats !== null ? '#fa459c' : '#fff'}}>
                            {title}
                        </span>
                        {
                            stats !== null ? (
                                <span className="fw-semibold fs-3x lh-1 ls-n2" style={{color: '#fa459c'}}>
                                    <CountUp end={stats === -1 ? 0 : stats} duration={1.5}/>
                                </span>
                            ) : (
                                <></>
                            )
                        }
                    </div>


                    {
                        svgIcon && (
                            <KTSVG path={svgIcon.path}
                                   className={'svg-icon svg-icon-2hx'}
                            />
                        )
                    }


                </div>
            </div>
        </>
    )
}

export {Card2}