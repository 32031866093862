import React, {ChangeEvent, FC} from 'react'
import {Functions} from '../../Functions'
import {PropsSelectAll} from '../../Types'

const SelectAll: FC<PropsSelectAll> = ({filterResults, setFilterResults, setCheckedAll, checkedAll, setIds}) => {

    const HandleSelect = (e: ChangeEvent<HTMLInputElement>) => {

        const {checked, ids, selected} = Functions.HandleSelectAll({e, data: filterResults})

        setCheckedAll(checked);
        setFilterResults(selected);
        setIds(ids);
    }

    return (
        <>
            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                <input className="form-check-input"
                       type="checkbox"
                       value="1"
                       name={'allSelect'}
                       checked={checkedAll}
                       onChange={HandleSelect}
                />
            </div>
        </>
    )
}

export {SelectAll}