import {Card, DateRange, Table, usePeriod} from '../../modules/all-pages'
import {CreateTicketModal, TicketRow} from './components'
import React, {ChangeEvent, useCallback, useMemo, useRef, useState} from 'react'
import {useSavContext} from './context/SavContext'
import {DataTickets, Ticket} from './types'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {useQuery} from 'react-query'
import {Get} from '../../modules/functions/get/Get'

const tableHead = (
    <>
        <th className="ps-4 fw-bolder text-muted bg-light min-w-150px text-capitalize">Date</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Sujet</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Services</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Détails</th>
        <th className="fw-bolder text-muted bg-light text-capitalize">Description</th>
        <th className="fw-bolder text-muted bg-light min-w-100px text-capitalize">satisfaction</th>
        <th className="fw-bolder text-muted bg-light min-w-100px text-capitalize">Status</th>
        <th />
    </>
)


const TICKETS_LIST = process.env.REACT_APP_SAV_TICKETS_LIST || 'sav/list-ticket'


const Tickets = () => {

    const [tickets, setTickets] = useState<DataTickets>({tickets: null})
    const [rangeValue, setRangeValue] = useState<ValueType>([subDays(new Date(), 29), new Date()])
    const [loadingStatus, setLoadingStatus] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle')
    const [dateTimeStart, dateTimeEnd] = rangeValue
    const period = usePeriod(dateTimeStart, dateTimeEnd)

    const {data} = useSavContext()

    const onSuccess = useCallback((newTickets: Ticket[]) => {
        setTickets((prevState) => ({
            ...prevState,
            tickets: newTickets,
        }))
        setLoadingStatus('fulfilled')
    }, [])

    const onError = useCallback((error: any) => {
        console.log('query: ', {error})
    }, [])

    useQuery(
        ['tickets_list', period],
        () => Get<DataTickets>({endpoint: TICKETS_LIST, period}),
        {
            onSuccess: (data) => {
                if (data?.tickets) onSuccess(data.tickets)
                else onSuccess([])
            },
            onError,
        },
    )

    const transformedData = useMemo(() => {
        if (tickets && data) {

            const statusMap = new Map(data.statusArray?.map(status => [status.name, status]))
            const transformedTickets = tickets.tickets?.map(ticket => ({
                ...ticket,
                status: statusMap.get(ticket.status) || {
                    id: 0,
                    name: ticket.status,
                    color: 'default',
                },
            }))
            return {tickets: transformedTickets}
        }
        return undefined
    }, [tickets])

    const [selectedStatus, setSelectedStatus] = useState('')
    const StatusRef = useRef(null)


    const filteredData = useMemo(
        () => {
            if (transformedData) {
                if (selectedStatus === '') return transformedData.tickets
                return transformedData.tickets?.filter(ticket => ticket.status.name === selectedStatus)
            }
            return []
        },
        [transformedData, selectedStatus],
    )

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value)
    }

    const tableBody = (
        <>
            {filteredData?.map(ticket => (
                <TicketRow key={ticket.id} ticket={ticket} />
            ))}
        </>
    )
    return (
        <Card id={'sav'} title={'SAV'}>
            <div className="row mb-5">
                <div className="col-12 d-flex justify-content-end align-items-center">
                    <CreateTicketModal setTickets={setTickets} />
                </div>
            </div>
            <div className="row d-flex justify-content-between align-items-end mb-10">
                <div className="col-4">
                    <div className="d-flex align-items-center">
                        <label htmlFor="category" className="text-gray-400 fs-8 fs-sm-7 me-2 me-sm-">Statut</label>
                        <select name="" id="" ref={StatusRef} onChange={handleStatusChange}
                                className="form-select form-select-sm form-select-solid">
                            <option value="">Tous</option>
                            {data?.statusArray?.map((status, index) => (
                                <option value={status.name} key={index}>{status.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <DateRange
                        rangeValue={rangeValue}
                        setRangeValue={setRangeValue}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={(status) => setLoadingStatus(status)}
                        className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>
            <Table thead={tableHead} tbody={tableBody} />
        </Card>
    )
}

export {Tickets}


