import {CustomProvider, DateRangePicker} from 'rsuite'
import React, {FC} from 'react'
import {PropsRangeValue, PropsWindowSize} from '../../Types'
import {LocalesRangeValue} from '../../Variables'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import isAfter from 'date-fns/isAfter'
import './RangeValue.scss'
import {RangeType, ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {UseWindowSize} from '../../hooks'

const RangeValue: FC<PropsRangeValue> = ({
                                             rangeValue,
                                             setRangeValue,
                                             count,
                                             setCount,
                                             loadingData,
                                             setLoadingData,
                                             className,
                                         }) => {

    // const [localeKey, setLocaleKey] = useState('fr_FR')
    const localeKey = 'fr_FR';
    const locale: {key: string, value: any} | undefined = LocalesRangeValue.find(item => item.key === localeKey)

    const handle = (value: ValueType) => {

        setRangeValue(value)
        setCount(count + 1)
        setLoadingData(true)
    }
    let clientCreationDate = window.localStorage.getItem("_clientCreationDate");

    const RANGES: RangeType[] = [
        {label: 'Tous', value: [new Date(`${clientCreationDate}`), new Date()]},
        {label: 'Aujourd\'hui', value: [new Date(), new Date()]},
        {label: 'Hier', value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
        {label: '7 derniers jours', value: [subDays(new Date(), 6), new Date()]},
        {label: '30 derniers jours', value: [subDays(new Date(), 29), new Date()]},
        {label: 'Ce mois-ci', value: [startOfMonth(new Date()), new Date()]},
        {
            label: 'Mois dernier',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        },
    ]

    const windowSize: PropsWindowSize = UseWindowSize()


    return (

        <div className={className}>

            {/* start::loading */}
            {
                loadingData && (
                    <span className="me-3" data-kt-indicator="on">
                        <span className="indicator-progress p-2">
                            <span className="spinner-border spinner-border-sm align-middle"/>
                        </span>
                    </span>
                )
            }
            {/* end::loading */}

            {/*  start::DateRangePicker  */}
            <form className='form'>
                <span className="text-gray-600 fw-semibold fs-8 me-3">Choisissez une période</span>
                <CustomProvider locale={locale?.value}>

                    {
                        windowSize.innerWidth >= 576
                            ? (
                                <DateRangePicker
                                    ranges={RANGES}
                                    placement={'bottomEnd'}
                                    format="dd/MM/yyyy"
                                    placeholder="jj/mm/aaaa ~ jj/mm/aaaa"
                                    value={rangeValue}
                                    onChange={(value) => handle(value)}
                                    onOk={(value) => handle(value)}
                                    style={{width: 250}}
                                    disabledDate={date => isAfter(date, new Date())}
                                    size="sm"
                                    // onClick={buttonsRemove}
                                />
                            )
                            : (
                                <DateRangePicker
                                    ranges={RANGES}
                                    placement={'bottom'}
                                    format="dd/MM/yyyy"
                                    value={rangeValue}
                                    onChange={(value) => {handle(value)}}
                                    style={{width: 250}}
                                    onOk={(value) => {handle(value)}}
                                    showOneCalendar
                                    disabledDate={date => isAfter(date, new Date())}
                                    size="sm"
                                />
                            )
                    }
                </CustomProvider>
            </form>
            {/*  end::DateRangePicker  */}
        </div>
  )
}

export {RangeValue}