import React from 'react'
import {PropsRadioButton} from '../TypePrivacyPolicy'
import {Field} from 'formik'

const RadioButton: React.FC<PropsRadioButton> = ({name, value, valuesFormik, textRadio}) => {

  return (
    <div className="">
      <div className="form-check form-check-custom form-check-solid form-check-sm">
        <Field className="form-check-input"
               type="radio"
               name={name}
               value={value}
               id={value}
        />
        <label
          className={`form-check-label text-hover-gray-600 fs-7 fs-sm-5 p-2 ${
            valuesFormik.pickedRadio && valuesFormik.pickedRadio.value === value ? 'text-primary' : 'text-muted'
          }`}
          htmlFor={value}
        >
          {textRadio}
        </label>
      </div>
    </div>
  )
}

export {RadioButton}