import React, {useEffect, useState} from 'react'
import { Formik, Field, Form } from 'formik';
import PostUser from "../../modules/functions/post/PostUser";
import GetUser from "../../modules/functions/get/GetUser";
import {Card} from '../../modules/all-pages'
import {CheckBoxButton, RadioButton} from './components'


// const PrivacyPolicyBreadCrumbs: Array<PageLink> = [
//     {
//         title: 'Politique de confidentialité',
//         path: '/pages/privacy-policy',
//         isSeparator: false,
//         isActive: false,
//     },
//     {
//         title: '',
//         path: '',
//         isSeparator: false,
//         isActive: false,
//     },
// ]

const initialValues = {
    durationEngagement: '0',
    pickedRadio: '',
    toggle_1: false,
    toggle_2: false,
    toggle_3: false,
    toggle_4: false,
}

const PrivacyPolicy: React.FC = () => {

    const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY,
        [id, setId] = useState(''),
        [durationEngagement, setDurationEngagement] = useState('0'),
        [pickedRadio, setPickedRadio] = useState(''),
        [toggle_1, setToggle_1] = useState(''),
        [toggle_2, setToggle_2] = useState(''),
        [toggle_3, setToggle_3] = useState(''),
        [toggle_4, setToggle_4] = useState(''),
        [loading, setLoading] = useState(false),
        [dataPrivacyPolicy, setDataPrivacyPolicy] = useState([]);

    initialValues.durationEngagement = durationEngagement;
    initialValues.pickedRadio = pickedRadio;
    initialValues.toggle_1 = toggle_1 === 'true';
    initialValues.toggle_2 = toggle_2 === 'true';
    initialValues.toggle_3 = toggle_3 === 'true';
    initialValues.toggle_4 = toggle_4 === 'true';

    useEffect( () => {

        GetUser(
            setId,
            null, null,
            setDataPrivacyPolicy,
        );

    }, []);

    setTimeout(() => {

        setDurationEngagement(dataPrivacyPolicy[0]);
        setPickedRadio(dataPrivacyPolicy[2]);
        setToggle_1(dataPrivacyPolicy[1]);
        setToggle_2(dataPrivacyPolicy[3]);
        setToggle_3(dataPrivacyPolicy[4]);
        setToggle_4(dataPrivacyPolicy[5]);
    }, 300);


    return (
        <>
            <Card id="_privacy_policy" title="Collecte des données personnelles">
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, {setStatus, setSubmitting}) => {

                        setLoading(true)
                        setSubmitting(false);

                        await new Promise(() => setTimeout((e: any) => {
                            
                            if (values.pickedRadio === undefined)   values.pickedRadio = '';

                            let dataPrivacyPolicy = {

                                duration: values.durationEngagement,
                                AnonOrSuprem: values.pickedRadio,
                                ConPersonnell: values.toggle_1,
                                ConListMarketing: values.toggle_2,
                                AutMemNouvContac: values.toggle_3,
                                AutMemImprContac: values.toggle_4,
                            }

                            PostUser(
                                {
                                    BaseUrl: PRIVACY_POLICY,
                                    id,
                                    dataForm: dataPrivacyPolicy,
                                }
                            ).then(() => {
                                setLoading(false)
                            })
                        }, 300));
                    }}
                >
                    {({ values }) => {

                        return (
                            <Form>

                                <div className='row mb-6'>
                                    <div className="col-sm-12">
                                        <CheckBoxButton name='toggle_1'
                                                        values={values}
                                                        toggle={toggle_1}
                                                        text='Consentetement du la collecte des données personnelles'
                                        />
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <div className='col-md-6'>
                                        <div className='d-flex align-items-center'>
                                            <RadioButton name='pickedRadio'
                                                         value='anonymize'
                                                         valuesFormik='values'
                                                         textRadio='Anonymiser les données contact'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='d-flex align-items-center'>
                                            <RadioButton name='pickedRadio'
                                                         value='delete'
                                                         valuesFormik='values'
                                                         textRadio='Supprimer les données et ses interactions'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-8">
                                    <div className="col-lg-4">

                                        <div className="d-flex align-items-center">
                                            <Field className="form-select form-select-solid form-select-sm"
                                                   aria-label="Select example"
                                                   as="select"
                                                   name="durationEngagement"
                                            >
                                                <option value="0">jamais</option>
                                                <option value="1">1 mois</option>
                                                <option value="2">2 mois</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center mt-3 fs-7 fs-sm-5">
                                            Après la date de dernier engagement du contact.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5 d-flex align-items-center">
                                    <div className="col-lg-8 text-gray-800 opacity-50">
                                        Les iteractions seront coservées et les données associées aux interactions seront supprimées.
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-lg-9">
                                        {/*<Tabs defaultActiveKey="en" id="uncontrolled-tab-example" className="mb-3">*/}

                                        {/*    <Tab eventKey="en" title="En" tabClassName={''}>*/}
                                        {/*        <TabLanguages textBefore={"Yes, I agree that the personal data collected will be kept for"}*/}
                                        {/*                      valuesFormik={values.durationEngagement != undefined ? values.durationEngagement: 0}*/}
                                        {/*                      month='months'*/}
                                        {/*                      textAfter={"as part of the connection with the company to access the services it provides."}*/}
                                        {/*        />*/}
                                        {/*    </Tab>*/}
                                        {/*    <Tab eventKey="fr" title="Fr" tabClassName={''}>*/}
                                        {/*        <TabLanguages textBefore={"Oui, j'accepte que les données personnelles collectés soient conservées"}*/}
                                        {/*                      valuesFormik={values.durationEngagement != undefined ? values.durationEngagement: 0}*/}
                                        {/*                      month='mois'*/}
                                        {/*                      textAfter={"dans le cadre de la mise en relation avec l'etreprise pour accéder aux prestations qu'elle fournit."}*/}
                                        {/*        />*/}
                                        {/*    </Tab>*/}
                                        {/*    <Tab eventKey="es" title="Es" tabClassName={''}>*/}
                                        {/*        <TabLanguages textBefore={'Sí, acepto que los datos personales recopilados se conservarán durante'}*/}
                                        {/*                      valuesFormik={values.durationEngagement != undefined ? values.durationEngagement: 0}*/}
                                        {/*                      month={'meses'}*/}
                                        {/*                      textAfter={'como parte de la conexión con la empresa para acceder a los servicios que presta.'}*/}
                                        {/*        />*/}
                                        {/*    </Tab>*/}
                                        {/*    <Tab eventKey="jp" title="Jp" tabClassName={''}>*/}
                                        {/*        <TabLanguages textBefore={'はい、私は、収集された個人データが、会社が提供するサービスにアクセスするための会社との接続の一部として'}*/}
                                        {/*                      valuesFormik={values.durationEngagement != undefined ? values.durationEngagement: 0}*/}
                                        {/*                      month={'ヶ月'}*/}
                                        {/*                      textAfter={'保持されることに同意します。'}*/}
                                        {/*        />*/}
                                        {/*    </Tab>*/}

                                        {/*</Tabs>*/}
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-center align-items-start">
                                        <a
                                            href='#'
                                            className='btn btn-sm btn-flex btn-light btn-active-light-primary fw-bolder'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                        >
                                            Consentement par défaut
                                        </a>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div className="col-ld-10">
                                        <CheckBoxButton name='toggle_2'
                                                        values={values}
                                                        toggle={toggle_2}
                                                        text='Consentetement de souscription à la liste de diffusion marketing'
                                        />
                                    </div>
                                </div>
                                <h3 className='text-info fs-5 mt-5 mb-3'>Création de contacts</h3>
                                <div className='row mb-6'>
                                    <div className="col-ld-10">
                                        <CheckBoxButton name='toggle_3'
                                                        values={values}
                                                        toggle={toggle_3}
                                                        text='Autoriser les membres du personnel à créer des nouveaux contacts'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div className="col-ld-10">
                                        <CheckBoxButton name='toggle_4'
                                                        values={values}
                                                        toggle={toggle_4}
                                                        text='Autoriser les membres du personnel à importer des contacts (Liste et API)'
                                        />
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end mt-6 pb-0 pt-6 px-0">
                                    <button type='submit' className='btn btn-primary'>
                                        {!loading && 'Enregistrer'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                            S'il vous plaît, attendez...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                        </span>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>

            </Card>
        </>
    )
}

export {PrivacyPolicy}
