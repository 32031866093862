import React, {useState} from "react";
import axios from "axios";
import {Redirect, useParams} from "react-router-dom";
import {SlugForgot} from "./TypesGet";
import {API_URL, localStorageKey} from '../../all-pages'

export default function Admin() {

    const [redirect, setRedirect] = useState(false);

    const GET_USER = process.env.REACT_APP_GET_USER,
        slug:SlugForgot = useParams();

    let localStorageValue = slug.slug;

    axios({

        method: 'GET',
        url: `${API_URL}${GET_USER}`,
        headers: {
            'Authorization': `Bearer ${localStorageValue}`,
        },
    })
        .then(function (response) {

            localStorage.setItem(localStorageKey, localStorageValue);
            if ( (localStorage.getItem(localStorageKey) !== null)) {

                setRedirect(true);
                window.location.reload();
            }

        })
        .catch((error) => {
            console.log(error);
        });

    if (redirect) {
        return <Redirect to='/dashboard' />
    }

    return <Redirect to='/login' />
}