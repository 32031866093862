import React, {FC, useRef, useState} from 'react'
import {useClickInside, useClickOutside} from '../../../modules/all-pages'
import {Tooltip, Whisper} from 'rsuite'
import {KTSVG} from '../../../../_metronic/helpers'

type PropsDropDown = {
    description: string
}

const DropDown: FC<PropsDropDown> = ({description}) => {

    const ref = useRef<HTMLDivElement | null>(null)
    const [toggle, setToggle] = useState<boolean>(false)

    useClickInside(ref, () => {
        if (!toggle) setToggle(true)
    })

    useClickOutside(ref, () => {
        if (toggle) setToggle(false)
    })


    return (
        <div className="position-relative d-flex justify-content-center align-content-center" ref={ref}>
            <Whisper placement="top" controlId="control-id-click" trigger="hover"
                     speaker={<Tooltip>Voir la description</Tooltip>}>
                <div
                    className={`btn btn-sm btn-icon btn-color-info btn-active-light-info ${toggle ? 'show menu-dropdown' : ''}`}
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                >
                    <KTSVG path={`/media/icons/duotune/general/gen005.svg`}
                           className="svg-icon-3 svg-icon-success" />
                </div>
            </Whisper>

            <div
                className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-275px ${toggle ? 'show ' : ''}`}
                style={{
                    zIndex: 105,
                    position: 'absolute',
                    inset: '0 auto auto 0',
                    margin: 0,
                    top: '35px',
                }}
                data-kt-menu-placement="bottom-end"
                data-kt-menu="true"
            >
                <div className="menu-item px-2 bg-light-warning">
                    <div className="menu-content fs-7 text-dark fw-bolder px-2 py-4">Description</div>
                </div>
                <div className="separator mb-1 opacity-75" />

                <div className="d-flex flex-column p-1">
                    <div className="menu-item px-2 mb-1">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {DropDown}