import {FC, useCallback, useMemo, useState} from 'react'
import {DataInterface} from '../types'
import {SavContext} from './SavContext'
import {ChildrenInterface} from '../../../../global'
import {QueryFunctionContext, QueryKey, useQueries, useQuery} from 'react-query'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {usePeriod} from '../../../modules/all-pages'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {Get} from '../../../modules/functions/get/Get'

const TICKETS_LIST = process.env.REACT_APP_SAV_TICKETS_LIST || 'sav/list-ticket'
const TICKETS_STATUS = process.env.REACT_APP_SAV_TICKETS_STATUS || 'sav/ticket-status'

const fetchData = async (endpoint: string, period: {start: string, end: string}): Promise<any> => {
    try {
        const parameter = period ? `?start=${period.start}&end=${period.end}` : ''
        const response = await SetupAxios.get(`${endpoint}${parameter}`)
        // console.log(response.data)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

const SavProvider: FC<ChildrenInterface> = ({children}) => {

    const [data, setData] = useState<DataInterface>({
        statusArray: null,
    })
    const [rangeValue, setRangeValue] = useState<ValueType>([subDays(new Date(), 29), new Date()])
    const [loadingStatus, setLoadingStatus] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle')
    const [dateTimeStart, dateTimeEnd] = rangeValue
    const period = usePeriod(dateTimeStart, dateTimeEnd)

    const onSuccess = useCallback((data: any) => {
        setData((prevState) => ({
            ...prevState,
            statusArray : data.status,
        }))
        setLoadingStatus('fulfilled')
    }, [])

    const onError = useCallback((error: any) => {
        console.log('query: ', {error})
    }, [])

    // const queries = useMemo(() => {
    //     return [
    //         {name: 'tickets_list', endpoint: TICKETS_LIST},
    //         {name: 'ticket_status', endpoint: TICKETS_STATUS},
    //     ].map(query => {
    //         const queryKey: QueryKey = [`sav_${query.name.toLowerCase()}`, period];
    //         const endpoint = query.endpoint
    //         return {
    //             queryKey,
    //             queryFn: (context: QueryFunctionContext<QueryKey, any>) => fetchData(endpoint, period),
    //             enabled: !!period,
    //             select: (data: any) => data,
    //             onSuccess: (data: any) => onSuccess(data, query.name),
    //             onError,
    //         }
    //     })
    //
    // }, [onError, onSuccess, period])
    //
    // useQueries(queries)

    useQuery(
        ['ticket_status', period],
        () => Get<DataInterface>({endpoint: TICKETS_STATUS, period}),
        {
            onSuccess: (data: DataInterface) => onSuccess(data),
            onError
        },
    )

    return (
        <>
            <SavContext.Provider value={{data, setData, rangeValue, setRangeValue, loadingStatus, setLoadingStatus}}>
                {children}
            </SavContext.Provider>
        </>
    )
}

export {SavProvider}