import {PropsCallTracking} from '../../pages/dashboard/ts'
import {Functions} from './Functions'
import {InterfaceDataList} from '../products/mailing/ts/types'

interface Props {
    setOptions: any,
    setSeries: any,
}

interface CallTracking extends Props {
    data: PropsCallTracking,
    setCallReceived: any,
    setCallMissed: any,
    setCallTime: any,
    setHour: any,
    setMinute: any,
    setSecond: any,
    setPercentageCallUnsuccessful: any,
}

interface WebForms extends Props {
    data: any,
    setFormsReceived: any,
    setFormsProcessed: any,
    setRestRate: any,
}

interface Mailing extends Props {
    chartInfo: InterfaceDataList,
    setCountCampaign: any,
    setCountMessages: any,
    setCampaignStats: any,
    setCountData: any,
    setContacts?: any,
    setOpens?: any,
    setClicks?: any,
    setNameLastCampaign: any,
    datesArray: any,
    dataClicked: any,
    dataDelivered: any,
    dataOpen: any,
    dataUnsubscribed: any,
    dataQueued: any,
}

export class FunctionsData {

    public static CallTracking({
                                   data,
                                   setCallReceived,
                                   setCallMissed,
                                   setCallTime,
                                   setHour,
                                   setMinute,
                                   setSecond,
                                   setPercentageCallUnsuccessful,
                                   setOptions,
                                   setSeries,
                               }: CallTracking) {

        setCallReceived(data.generalAppelRecu)
        setCallMissed(data.countCallsMissed)
        const time = data.generalTempsAppel.replace(/\s+/g, '')
        setCallTime(time)

        const splitH = time.split('h'),
            splitM = splitH[1].split('m'),
            splitS = splitM[1].split('s')

        const h = splitH[0],
            m = splitM[0],
            s = splitS[0]

        setHour(h)
        setMinute(m)
        setSecond(s)

        setPercentageCallUnsuccessful(data.percentageCallsMissed)

        const dates = JSON.parse(data.statsCalls.chartLabels)
        const dataOfNumberOfCallsReceived = JSON.parse(data.statsCalls.nbrCalls),
            dataOfNumberOfCallsMissed = JSON.parse(data.statsCalls.totalMissed),
            dataOfNumberOfCallsSuccess = JSON.parse(data.statsCalls.totalSuccess)

        const getDates = Functions.splitDates({dates})

        setOptions({xaxis: {categories: dates}})
        setSeries([
            {name: 'Nombre d\'appels reçus', data: dataOfNumberOfCallsReceived},
            // {name: "Nombre d'appels repondus", data: dataOfNumberOfCallsSuccess},
            {name: 'Nombre d\'appels non aboutis', data: dataOfNumberOfCallsMissed},
        ])
    }

    public static WebForms({
                               data,
                               setFormsReceived,
                               setFormsProcessed,
                               setRestRate,
                               setOptions,
                               setSeries,
                           }: WebForms) {

        const {labels} = data,
            {totalReceived} = data,
            {totalTrait} = data

        const {runningTotalFormReceived, runningTotalFormTrait, runningResponseRate} = data

        setFormsReceived(runningTotalFormReceived)
        setFormsProcessed(runningTotalFormTrait)
        setRestRate(runningResponseRate)

        const getDates = Functions.splitDates({dates: labels})

        setOptions({xaxis: {categories: labels}})

        setSeries([
            {name: 'Formulaires reçus', data: totalReceived},
            {name: 'Formulaires traités', data: totalTrait},
        ])
    }

    public static Mailing({
                              chartInfo,
                              setCountCampaign,
                              setCountMessages,
                              setCampaignStats,
                              setCountData,
                              setContacts,
                              setOpens,
                              setClicks,
                              setNameLastCampaign,
                              datesArray,
                              dataClicked,
                              dataDelivered,
                              dataOpen,
                              dataUnsubscribed,
                              dataQueued,
                              setOptions,
                              setSeries,
                          }: Mailing) {

        const {campaignStats, chartData, countData} = chartInfo;

        const {clicks, delivered, opens, queued, unsubscribers, dates} = chartData
        const {countCampaigns, countMessages, lastCampaign} = countData

        setCountCampaign(countCampaigns)
        setCountMessages(countMessages)

        setCampaignStats(campaignStats)
        setCountData(countData)

        if(setContacts) setContacts(countData.countDataContacts)
        if(setOpens) setOpens((campaignStats.ratios.open * 100).toFixed(2))
        if(setClicks) setClicks((campaignStats.ratios.click * 100).toFixed(2))

        lastCampaign.map((i) => setNameLastCampaign(i.name))

        datesArray = [...dates]
        dataClicked = [...clicks.data]
        dataDelivered = [...delivered.data]
        dataOpen = [...opens.data]
        dataUnsubscribed = [...unsubscribers.data]
        dataQueued = [...queued.data]

        const getDates = Functions.splitDates({dates: datesArray})

        setOptions({xaxis: {categories: datesArray}})

        setSeries([
            {name: 'Cliqués', data: dataClicked},
            {name: 'Délivrés', data: dataDelivered},
            {name: 'Ouverts', data: dataOpen},
            {name: 'Désabonnés', data: dataUnsubscribed},
            {name: 'En attente', data: dataQueued},
        ])
    }

}
