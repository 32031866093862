import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import React, {FC} from 'react'

type PropsMsgStatus = {
    color: string,
    icon: string,
    status: string,
}

const Status: FC<PropsMsgStatus> = ({color, icon, status}) => {

    return (
        <>
            {
                (status)
                    ? (
                        <div className={`alert alert-${color} d-flex align-items-center p-2 mb-7`}>
                            <span className={`svg-icon svg-icon-2hx svg-icon-${color} me-4`}>
                                <SVG src={toAbsoluteUrl(`/media/icons/duotune/general/${icon}.svg`)}/>
                            </span>
                            <div className="d-flex flex-column">
                                <span>{status}</span>
                            </div>
                        </div>
                    )
                    : (<></>)
            }
        </>
    )
}

export {Status}