import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
    id: string,
    title: string,
    media: string,
    body: React.ReactNode;
    footer?: React.ReactNode;
}

const Modal: FC<Props> = ({id, title , media, body, footer}) => {

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_${id}`}>
                <div className={`modal-dialog modal-${media}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                        {
                            footer
                                ? (
                                    <div className="modal-footer">
                                        {footer}
                                    </div>
                                )
                                : (<></>)
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export {Modal}