import axios, {AxiosError} from 'axios'
import ErrorCatch from '../ErrorCatch'
import {API_URL, localStorageKey} from '../../all-pages'

export default function LoginPost(
    data: object,
    setRedirect: any,
    setLoading: any,
    setSubmitting: any,
    setStatus: any,
    msgSuccess: string,
    msgError: string,
    setColor: any,
) {

    const LOGIN = process.env.REACT_APP_LOGIN

    console.log(`${API_URL}${LOGIN}`)

    axios({

        method: 'POST',
        url: `${API_URL}${LOGIN}`,
        headers: {
            "Content-Type": "application/json"
        },
        data: data,
    })
        .then(function(response) {

            const {data} = response
            const {status, message} = data


            if (status === 403 && message !== '') {

                setStatus(message)
            } else {
                localStorage.setItem(localStorageKey, response.data.token)
                // setRedirect(true);
                window.location.href = '/';
            }


        })
        .catch(function(e: AxiosError) {

            ErrorCatch(setLoading, setSubmitting, setStatus, msgSuccess, e.message, setRedirect, null, setColor)
        })
}
