import React from 'react'
import {HandleFileChange, HandleFileClick} from '../types'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

const FileInput = ({fileInputRef, filesCount, setFilesCount, formik}: any) => (
    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
        {/*<div className="input-group">*/}
        {/*<input*/}
        {/*    type="file"*/}
        {/*    name="files"*/}
        {/*    ref={fileInputRef}*/}
        {/*    onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
        {/*        const files = Array.from(e.target.files || [])*/}
        {/*        formik.setFieldValue('files', files)*/}
        {/*        setFilesCount(files.length)*/}
        {/*    }}*/}
        {/*    className="form-control form-control-solid"*/}
        {/*    multiple*/}
        {/*/>*/}
        {/*</div>*/}
        <div className="fv-row mb-8">
            <label className="fs-6 fw-semibold mb-2">Pièce(s) jointe(s)</label>

            <div
                className="btn btn-outline btn-outline-dashed btn-outline-default d-flex justify-content-between text-start p-6 mb-6 active"
                onClick={() => HandleFileClick({fileInputRef})}
            >
                <div className="d-flex">
                    <KTSVG path={toAbsoluteUrl('/media/svg/files/upload.svg')}
                           className="svg-icon svg-icon-3hx" />
                    <div className="ms-4">
                        <span className="fs-5 fw-bolder text-gray-900 mb-2 d-block">Ajouter des fichiers</span>
                        <span
                            className="fw-bold fs-7 text-muted d-block">.png, .jpg, .jpeg, .pdf, .docx, .csv</span>
                    </div>
                </div>
                <span className="fw-bold fs-6 text-gray-900 d-block">
                                                {filesCount > 0 ? `${filesCount} fichiers` : 'Aucun fichier choisi'}
                                        </span>
            </div>
            <div>
                <input type="file"
                       ref={fileInputRef}
                       accept=".png, .jpg, .jpeg, .pdf, .docx, .csv"
                       multiple
                       onChange={(e) => HandleFileChange({e, formik, setFilesCount})}
                       style={{display: 'none'}}
                />
            </div>
        </div>


    </div>
)

export {FileInput}