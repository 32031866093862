import {Dispatch, SetStateAction} from 'react'


export interface InterfaceDataAnnouncement {
  data: {
    annonce_homepage_1: InterfaceAnnouncement
  }
}
export interface InterfaceAnnouncement {
  button: {
    title: string;
    href: string;
  } | null;
  icon: {
    src: string
    title: string
    text: string
  }[] | []
  image: string | null;
  sub_title: string;
  text: string;
  title: string;
}

export interface InterfaceData {
  conversion: number;
  numberVisit: number;
  chartOptions: {
    series: any[];
    categoriesXAxis: string[];
  };
  cleverPhone: {
    generalCallReceived: number;
    clickOnTel: number;
  }
  allFormsReceived: number;
  announcement: any;
  callLog?: any;
}

export const initialData: InterfaceData = {
  conversion: 0,
  numberVisit: 0,
  chartOptions: {
    series: [],
    categoriesXAxis: [''],
  },
  cleverPhone: {
    generalCallReceived: 0,
    clickOnTel: 0,
  },
  allFormsReceived: 0,
  announcement: null,
};

type PropsPeriod = {
  start: string;
  end: string;
}


/**
 * CallTracking
 */
type PropsDashboardCallTracking = PropsProducts & {
  setLoadingData: Dispatch<SetStateAction<boolean>>;
  setLabelLength: Dispatch<SetStateAction<number>>;
}

type PropsCallTracking = {
  countCallsMissed: number;
  countCallsSuccess: number;
  generalAppelRecu: number;
  percentageCallsMissed: number;
  percentageCallsSuccess: number;
  generalTempsAppel: string;
  statsCalls: StaticCall;
}

type StaticCall = {
  averageCalls: string;
  nbrCalls: string;
  chartLabels: string;
  hours: string;
  totalMissed: string;
  totalSuccess: string;
}

/**
 * WebForms
 */

type PropsDashboardWebForms = PropsProducts & {
  strokeColor: string;
  setLoadingData: Dispatch<SetStateAction<boolean>>;
}

/**
 * Mailing
 */

type PropsDashboardMailing = PropsProducts & {
  setLoadingData: Dispatch<SetStateAction<boolean>>;

}

type PropsProducts = {
  className: string;
  chartColor: string;
  chartHeight: string;
  period: PropsPeriod;
  count: number;
}

interface ContactsDataFormItem {
  type: 'data_form';
  id: number;
  form_id: number;
  data: string;
  created_at: string;
  updated_at: string;
  status: string;
  notes: string | null;
  message: string | null;
  replay_at: string | null;
  trait_at: string | null;
  date: string;
}

interface ContactsCallTrackingItem {
  type: 'callTraking';
  id: number;
  called_at: string;
  callID: string;
  username: string;
  cleverphone: string;
  transactiontype: string;
  callFrom: string;
  transferTo: string;
  duration: number;
  created_at: string;
  updated_at: string;
  clientid: number | null;
  date: string;
}

type ContactsItem = ContactsDataFormItem | ContactsCallTrackingItem;

export type {
  PropsPeriod,
  PropsDashboardCallTracking,
  PropsCallTracking,
  StaticCall,
  PropsDashboardWebForms,
  PropsDashboardMailing,
  ContactsDataFormItem,
  ContactsCallTrackingItem,
  ContactsItem,
}