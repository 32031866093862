import React, {FC, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {rotate_0, rotate_180} from '../../Variables'
import {KTSVG} from '../../../../../_metronic/helpers'
import {PropsCard} from '../../Types'

const CardCollapse: FC<PropsCard> = ({id, title, children,}) => {

    const [toolbarIcon, setToolbarIcon] = useState<boolean>(true);
    const {location} = useHistory();

    const padding = ["/statistiques/s-seo", "/statistiques/s-sea", "/pages/privacy-policy"].includes(location.pathname);

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className={`card-header border-0 cursor-pointer ${!"/pages/privacy-policy".includes(location.pathname) ? "" : "px-2 px-sm-10"}`}
                     role='button'
                     data-bs-toggle='collapse'
                     data-bs-target={`#kt_${id}_details`}
                     aria-expanded='true'
                     aria-controls={`kt_${id}_details`}
                     onClick={() => setToolbarIcon(!toolbarIcon)}
                >
                    <div className='card-title m-0 '>
                        <h3 className={`fw-bolder m-0 fs-5 fs-sm-3`}>{title}</h3>
                    </div>
                    <div className="card-toolbar">
                        <div className='btn btn-icon w-auto px-0' style={toolbarIcon ? rotate_0 : rotate_180}
                        >
                            <KTSVG path={'/media/icons/duotune/arrows/arr073.svg'}
                                   className={'svg-icon-1 svg-icon-primary'}
                            />
                        </div>
                    </div>
                </div>

                <div id={`kt_${id}_details`} className='collapse show'>
                    <div className={`card-body border-top ${!padding ? "px-3" : "p-4"} p-sm-4 p-md-8`}>
                        { children }
                    </div>
                </div>
            </div>
        </>
    )
}

export {CardCollapse}