import {FC, useState} from 'react'
import {useQuery} from 'react-query'
import {API_URL} from '../../../modules/all-pages'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {Link} from 'react-router-dom'

interface Contact {
    email: string
    first_name: string
    last_name: string
    number: string
    id: number
}

const CONTACTS = process.env.REACT_APP_DASHBOARD_CONTACTS || 'last-contact'

const fetchDashboardData = async (endpoint: string) => {
    const response = await SetupAxios.get(`${API_URL}${endpoint}`)
    return response.data
}

const ContactsDashboard: FC = () => {

    const colors = ['success', 'primary', 'info', 'warning', 'danger'],
        [contacts, setContacts] = useState<Contact[]>([])

        useQuery(
            `dashboard_contacts`,
            () => fetchDashboardData(CONTACTS),
            {
                select: (data: any) => {
                    return data.map((item: any) => {
                        const {email, first_name, last_name, number, id} = item
                        return {email, first_name, last_name, number, id}
                    })
                },
                onSuccess: (data) => {
                    setContacts(data)
                },
            },
        )


    return (
        <>
            <div className="card-header border-0">
                <h3 className="card-title fw-bolder text-dark">5 derniers contacts</h3>
            </div>
            <div className="card-body p-3">
                {
                    contacts.length
                        ? (
                            contacts.map((contact, index) => {

                                const {email, first_name, last_name, number, id} = contact

                                return (
                                    <div className="d-flex align-items-center mb-8" key={index}>
                                        <span className={`bullet bullet-vertical h-40px me-5 bg-${colors[index]}`}/>
                                        <div className="flex-grow-1">
                                            <Link to={`/contacts/modifier-contact/${id}`}
                                                  className="text-gray-800 text-hover-primary fw-bolder fs-7">
                                                {first_name} {last_name}
                                            </Link>
                                            <span className="text-muted fw-bold d-block fs-9">{email}</span>
                                        </div>
                                        <span
                                            className={`badge badge-light-${colors[index]} fs-9 fw-bolder`}>{number}</span>
                                    </div>

                                )
                            })
                        )
                        : <h4 className="text-gray-800 fs-6">La liste des 5 derniers contacts est vide</h4>
                }

            </div>
        </>
    )
}

export {ContactsDashboard}