import {useEffect, useState} from 'react'
import {PropsWindowSize} from '../Types'

const UseWindowSize = () => {

    const [windowSize, setWindowSize] = useState<PropsWindowSize>({
        innerWidth: 0,
    });

    const handleWindowResize = () => {
        setWindowSize({innerWidth: window.innerWidth})
    }

    useEffect(() => {

        handleWindowResize();
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }

    }, [])

    return windowSize;
}

export {UseWindowSize}