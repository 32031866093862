import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import React, {ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState} from 'react'
import {Modal} from '../../../modules/all-pages'
import {useQuery} from 'react-query'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import SVG from 'react-inlinesvg'
import {useSavContext} from '../context/SavContext'
import {FileInput, FormField, FormSelect} from './index'
import {DataTickets} from '../types'

const TICKETS_FORM = process.env.REACT_APP_SAV_TICKETS_FORM || 'sav/data-ticket'
const TICKETS_CREATE = process.env.REACT_APP_SAV_TICKETS_CREATE || 'sav/save-ticket'

export interface Root {
    products: Product[]
    assigns: Assigns[]
}

export interface Product {
    id: number
    name: string
}

export interface Assigns extends Product {
    product_id: number
}

const fetchData = async (endpoint: string): Promise<Root> => {
    try {
        const response = await SetupAxios.get<Root>(endpoint)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

interface FormValues {
    subject: string
    products: string
    assigns?: string
    assignTitle?: string;
    description: string
    files: File[]
}

const initialValues: FormValues = {
    subject: '',
    products: '',
    description: '',
    files: [] as File[],
}

const validationSchema = Yup.object({
    subject: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 100 symbols')
        .required('Obligatoire'),
    products: Yup.string()
        .required('Obligatoire')
        .nullable(),

    description: Yup.string()
        .required('Obligatoire'),
    files: Yup.mixed(),
    assigns: Yup.string().when('products', {
        is: (val: string) => val !== '7' && val !== '6',
        then: Yup.string()
            .required('Obligatoire'),
    }),
    // assignTitle: Yup.string().when('products', {
    //     is: (val: string) => val === '7',
    //     then: Yup.string()
    //         .required('Obligatoire'),
    // }),
})

const postData = async (formData: any): Promise<any> => {
    try {
        const response = await SetupAxios.post(TICKETS_CREATE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    } catch (error) {
        console.error('Error posting data:', error)
        throw error
    }
}

interface ModalInterface {
    setTickets: Dispatch<SetStateAction<DataTickets>>
}

const CreateTicketModal: FC<ModalInterface> = ({setTickets}) => {

    const {setData} = useSavContext(),
        [products, setProducts] = useState<Product[]>([]),
        [assigns, setAssigns] = useState<Assigns[]>([]),
        [selectedProductId, setSelectedProductId] = useState<number | null>(null),
        [loading, setLoading] = useState(false),
        [filesCount, setFilesCount] = useState<number>(0),
        productsRef = useRef<HTMLSelectElement | null>(null),
        modalCloseRef = useRef<HTMLButtonElement | null>(null),
        fileInputRef = useRef<HTMLInputElement | null>(null),
        [additionalField, setAdditionalField] = useState<string | null>(null)

    useQuery('tickets_form', () => fetchData(TICKETS_FORM), {
        onSuccess: (data) => {
            setProducts(data.products)
            setAssigns(data.assigns)
        },
    })

    const assignsFromProductsSelected = useMemo(
        () => (selectedProductId ? assigns.filter(assign => assign.product_id === selectedProductId) : []),
        [selectedProductId, assigns],
    )

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(e.target.value, 10)
        setSelectedProductId(isNaN(selectedId) ? null : selectedId)
        formik.handleChange(e)
        setAdditionalField(selectedId === 6 || selectedId === 7 ? null : 'select')
    }

    const handleModalClose = useCallback(
        () => {
            if (modalCloseRef.current) modalCloseRef.current?.click()
        },
        [],
    )

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {resetForm, setSubmitting, setStatus}) => {
            setLoading(true)
            setSubmitting(false)

            const formData = new FormData()
            formData.append('subject', values.subject)
            formData.append('product', values.products)
            if (values.assigns && additionalField === 'select') formData.append('assign', values.assigns)
            // if (values.assignTitle && additionalField === 'input') formData.append('assign_title', values.assignTitle)
            formData.append('description', values.description)
            values.files.forEach(file => formData.append('files[]', file))

            try {
                const response = await postData(formData)
                if (response.status === 200) {
                    setTickets((prevState) => ({
                        ...prevState,
                        tickets: prevState?.tickets ? [response.ticket, ...prevState.tickets] : [response.ticket],
                    }))
                    setStatus('Le ticket a été créé avec succès')
                    setTimeout(() => {
                        resetForm()
                        handleModalClose()
                    }, 1000)
                }
            } catch (error) {
                console.error('Error:', error)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <>
            <button type="button"
                    className="btn btn-sm btn-light-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_new_ticket"

            >
                <KTSVG path="/media/icons/duotune/general/gen035.svg" className="svg-icon-2 me-2" />
                Créer un ticket
            </button>

            <Modal
                id="new_ticket"
                title="Créer un ticket"
                media="lg"
                body={
                    <form id="kt_modal_new_ticket_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                          onSubmit={formik.handleSubmit}>
                        <FormField type="text"
                                   label="Sujet"
                                   touched={formik.touched.subject}
                                   error={formik.errors.subject}
                                   formikProps={formik.getFieldProps('subject')}
                        />
                        <div className="row g-9 mb-4 mb-md-8">
                            <div className="col-md-6 fv-row">
                                <FormSelect label="Services"
                                            name="products"
                                            touched={formik.touched.products}
                                            error={formik.errors.products}
                                            value={formik.values.products || ''}
                                            options={products}
                                            onChange={handleSelectChange}
                                            ref={productsRef}
                                />
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                {additionalField === 'select' && (
                                    <FormSelect
                                        label="Détails"
                                        name="assigns"
                                        touched={formik.touched.assigns}
                                        error={formik.errors.assigns}
                                        value={formik.values.assigns || ''}
                                        options={assignsFromProductsSelected}
                                        disabled={assignsFromProductsSelected.length === 0}
                                        onChange={formik.handleChange}
                                    />
                                )}
                            </div>
                        </div>

                        <FormField type="textarea"
                                   label="Description"
                                   touched={formik.touched.description}
                                   error={formik.errors.description}
                                   formikProps={formik.getFieldProps('description')}
                                   rows={4}
                        />
                        <FileInput fileInputRef={fileInputRef}
                                   filesCount={filesCount}
                                   setFilesCount={setFilesCount}
                                   formik={formik}
                        />
                        {formik.status && (
                            <div className="alert alert-success d-flex align-items-center p-2 mb-7">
                                <span className="svg-icon svg-icon-2hx svg-icon-success mx-2">
                                    <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen048.svg')} />
                                </span>
                                <div className="d-flex flex-column">
                                    <span>{formik.status}</span>
                                </div>
                            </div>
                        )}
                        <div className="text-center">
                            <button type="button" className="btn btn-sm btn-light me-2 px-6" data-bs-dismiss="modal"
                                    ref={modalCloseRef}
                                    onClick={handleModalClose}
                            >
                                Fermer
                            </button>
                            <button type="submit"
                                    id="kt_modal_new_ticket_submit"
                                    className="btn btn-primary"
                                    disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className="indicator-label">Envoyer</span>}
                                {loading && (
                                    <span className="indicator-progress" style={{display: 'block'}}>
                                        S'il vous plaît, attendez...
                                        <span
                                            className="spinner-border spinner-border-sm align-middle ms-2" />
                                   </span>
                                )}
                            </button>
                        </div>
                    </form>
                }
            />
        </>
    )
}

export {CreateTicketModal}

