import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
    show: boolean,
}

const styles = {

    hideNotification: {
        right: "-1rem",
        top: `16.6%`,
        zIndex: 99999999999,
        opacity: 0,
        transition: ".3s ease"
    },
    showNotification: {
        right: "2rem",
        top: `16.6%`,
        zIndex: 99999999999,
        opacity: 1,
        transition: ".3s ease"
    },
}



const Notification: FC<Props> = ({show}) => {

    return (
        <>
            <div className="alert alert-success d-flex align-items-center p-2 position-fixed"
                 style={show ? styles.showNotification : styles.hideNotification}
            >
                <span className="svg-icon svg-icon-2x svg-icon-success me-5">
                    <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-1' />
                </span>
                <div className="flex-grow-1 me-2">
                    <a href="#" className="fw-bold fs-7 text-gray-800">Campagne archivée</a>
                </div>
            </div>
        </>
    )
}

export {Notification}