import React from "react";

export default function FileDownload({data, fileName, fileExtension}: {data: any, fileName: string, fileExtension: string}) {

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
}