/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'

type Props = {
    className: string
}

const ChartsWidget7: React.FC<Props> = ({className}) => {

    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {

        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }


    }, [chartRef])


    return (
        <></>
        // <div className={`card ${className}`}>
        //
        //
        //   {/* begin::Header */}
        //   <div className='card-header border-0 pt-5'>
        //     <h3 className='card-title align-items-start flex-column'>
        //       <span className='card-label fw-bolder fs-3 mb-1'>Titre</span>
        //       <span className='text-muted fw-bold fs-7'>Sous-titre</span>
        //     </h3>
        //   </div>
        //   {/* end::Header */}
        //
        //   {/* begin::Section1 */}
        //   <div className='card-body'>
        //     {/* begin::Chart */}
        //     <div
        //       ref={chartRef}
        //       id='kt_charts_widget_7_chart'
        //       style={{height: '350px'}}
        //       className='card-rounded-bottom'
        //     />
        //     {/* end::Chart */}
        //   </div>
        //   {/* end::Section1 */}
        // </div>
    )
}


export {ChartsWidget7}

function getChartOptions(height: number): ApexOptions {


    const labelColor = getCSSVariableValue('--bs-gray-500'),
        borderColor = getCSSVariableValue('--bs-gray-200'),
        strokeColor = getCSSVariableValue('--bs-gray-300'),
        color1 = getCSSVariableValue('--bs-warning'),
        color1Light = getCSSVariableValue('--bs-light-warning'),
        color2 = getCSSVariableValue('--bs-success'),
        color2Light = getCSSVariableValue('--bs-light-success'),
        color3 = getCSSVariableValue('--bs-primary'),
        color3Light = getCSSVariableValue('--bs-light-primary')

    return {
        series: [
            {
                name: 'Bénéfice net',
                data: [35, 35, 50, 50, 90, 90],
            },
            {
                name: 'Revenue',
                data: [70, 70, 20, 20, 20, 55],
            },
            {
                name: 'Dépenses',
                data: [30, 30, 40, 40, 60, 60],
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: height,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 1800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: [color1, 'transparent', 'transparent'],
        },
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function(val) {
                    return '€' + val + ' milliers'
                },
            },
        },
        colors: [color1, color2, color3],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            colors: [color1Light, color2Light, color3Light],
            strokeColors: [color1, color2, color3],
            strokeWidth: 3,
        },
    }
}
