import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import React, {useState, useEffect, FC, ReactNode} from 'react'
import {Card1} from './Card1'
import {useReport} from '../context/ReportProvider'
import {stripHtml} from '../utils/functions'
import { useUser } from '../../../modules/context/types'

const imgPath = toAbsoluteUrl('/media/logos/Logo-WF_1.png')

const ReportHeader = () => {

    const [base64String, setBase64String] = useState<string>(''),
        {
            period,
            conversion,
            seo, administration,
        } = useReport(),
        {firstname, lastname, email, domainName, } = useUser()

    const {
        data: {position_header},
        loading: loadingAdministration,
    } = administration

    const {start, end} = period

    useEffect(() => {
        fetch(imgPath)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    const base64String = reader.result as string
                    setBase64String(base64String)
                }
            })
            .catch(error => {
                console.error('Error converting image to base64:', error)
            })
    }, [])

    return (
        <>
            <div className="row p-5">
                <div className="col-4">
                    <div className="row mb-5 d-flex justify-content-center align-items-center">
                        <div className="col-12">
                            <img src={imgPath} style={{width: '260px'}} alt="mywebforce"/>
                        </div>
                    </div>
                    <div className="row my-5 d-flex justify-content-center align-items-center">
                        <div className="col-11">
                            <IsLoading slice={4} alignItems={'start'} loading={loadingAdministration}
                                       height={'h-15px'}>
                                <div className="text-gray-800 fs-7">
                                    <div className="fs-6 mb-1">Bonjour {firstname} {lastname}</div>
                                    <div className="fs-6 mb-1">{domainName}</div>
                                    <div className="fs-6 mb-1">Voici votre rapport d'activité</div>
                                    <div className="fs-6 mb-1">{start} ~ {end}</div>
                                </div>
                            </IsLoading>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a href="#" className="btn btn-sm fw-bolder text-white" style={{backgroundColor: '#55b5f7', borderRadius: 12}}>
                                Votre compte MyWebforce
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <IsLoading slice={1} alignItems={'start'} loading={loadingAdministration}
                               height={'h-20px'}>
                        <h2 className="text-primary">Détails des {seo.nombreVisites} visites sur votre solution Webforce :</h2>
                    </IsLoading>
                    <div className="row my-5">
                        <div className="col-4">
                            <Card1 title={'visites'} stats={seo.nombreVisites} backgroundColor={'#fa459c'} color={'#fff'}/>
                        </div>
                        <div className="col-4">
                            <Card1 title={'pages vues'} stats={+seo.nombrePagesVues} backgroundColor={'#cdecff'} color={'#1096f3'}/>
                        </div>
                        <div className="col-4">
                            <Card1 title={'conversions'} stats={conversion} backgroundColor={'#fa459c'} color={'#fff'}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11">
                            <p className="fs-7" style={{lineHeight: 1.7}}>
                                {position_header.text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {ReportHeader}

type PropsIsLoading = {
    alignItems: 'start' | 'center';
    children: ReactNode;
    loading: boolean;
    slice: number;
    height?: string
}
const IsLoading: FC<PropsIsLoading> = ({alignItems, children, loading, height, slice}) => {

    const array = [
        {width: 'w-100'},
        {width: 'w-75'},
        {width: 'w-25'},
        {width: 'w-100'},
        {width: 'w-100'},
        {width: 'w-25'},
    ]

    return (
        <>
            {
                loading ? (
                    <div className={`w-100 d-flex justify-content-center align-items-${alignItems} flex-column`}>
                        {
                            array.slice(0, slice).map(({width}, index) => (
                                <div className={`bg-light-dark mb-3 ${width} ${height ? height : 'h-30px'}`}
                                     key={index}/>
                            ))
                        }
                    </div>
                ) : children
            }

        </>
    )
}