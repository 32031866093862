import React from "react";

/**
 * v1.0
 * update v1.1
 * *    dateTime.toJSON().slice(0,10).replace(/-/g,'/')
 */
type Props = {}


export default function ExtractDate({
    dateTime, separator, localeString} : {dateTime: string, separator: string, localeString?: boolean}) {

    let dateString = '', longMonth = '', dayString = '', monthString = '', yearString = '',
        dayNumber = 0, monthNumber = 0, yearNumber = 0,
        splitDate: string[], date: Date;

    dateString = dateTime.split(separator)[0];
    splitDate = dateString.split('-');

    yearString = splitDate[0];
    monthString = splitDate[1];
    dayString = splitDate[2];

    /**
     * long Month
     */
    if (monthString[0] === '0') { monthNumber = parseInt(monthString[1]); }
    monthNumber = parseInt(monthString);

    date = new Date(yearNumber, monthNumber, dayNumber);
    longMonth = date.toLocaleString('fr-FR', { month: 'long' });
    longMonth = longMonth[0].toUpperCase() + longMonth.slice(1);

    if (localeString) {

        return [dayString, longMonth, yearString];
    }

    return [dayString, monthString, yearString];
}