import {PropsData} from '../../pages/contacts/Types'
import {PropsHandleSearch, PropsHandleSelect} from './Types'

type PropsPagination = {
    currentPage: number
    listsPerPage: number
    data: any
}

type PropsSplitDates = {
    dates: string[],
}

export class Functions {

    public static splitDates({dates}: PropsSplitDates) {

        const split = dates.map((date) => date.split('-')[0])
        let a: string[] = [];

        if (split.length > 9) a = split.map((item, i) => (i % 3 === 0) ? item : '')
        if (split.length > 19) a = split.map((item, i) => (i % 4 === 0) ? item : '')
        if (split.length > 29) a = split.map((item, i) => (i % 5 === 0) ? item : '')
        if (split.length > 49) a = split.map((item, i) => (i % 10 === 0) ? item : '')
        if (split.length > 69) a = split.map((item, i) => (i % 20 === 0) ? item : '')

        return a;
    }

    public static getNumberOfXAxis({dates}: PropsSplitDates) {

        const split = dates.map((date) => date.split('-')[0])
        let a: string[] = [];

        if (split.length > 9) a = split.map((item, i) => (i % 3 === 0) ? item : '')
        if (split.length > 19) a = split.map((item, i) => (i % 4 === 0) ? item : '')
        if (split.length > 29) a = split.map((item, i) => (i % 5 === 0) ? item : '')
        if (split.length > 49) a = split.map((item, i) => (i % 10 === 0) ? item : '')
        if (split.length > 69) a = split.map((item, i) => (i % 20 === 0) ? item : '')

        return a;
    }

    public static IdsItemsChecked(items: any[]) {

        return items.filter((item) => item.isChecked)
            .map((item) => item.id);
    }

    public static HandleSelectAll = ({e, data}: PropsHandleSelect) => {

        const {checked} = e.target;

        const selected = data.map((item: any) => {
            return {...item, isChecked: checked}
        });
        const ids = Functions.IdsItemsChecked(selected);

        return {checked, ids, selected}
    }

    public static HandleSelect = ({e, data}: PropsHandleSelect) => {

        const {name, checked} = e.target;
        const selected = data.map((item: any) => {
            if (item.id == +name) return {...item, isChecked: checked}
            else return item
        })

        const allSelected = (item: PropsData) => item.isChecked === true;
        const ids = Functions.IdsItemsChecked(selected);
        const all = selected.every(allSelected);

        return {all, ids, selected};
    }

    public static HandleSearch ({value, data}: PropsHandleSearch) {

        const search = data.filter((item: any) => {

            return Object.values(item)
                .join('')
                .toLowerCase()
                .includes(value.toLowerCase())
        })

        return {search}
    }

    public static Pagination ({currentPage, listsPerPage, data} : PropsPagination): any[] {

        const indexOfLastList = currentPage * listsPerPage,
            indexOfFirstList = indexOfLastList - listsPerPage,
            currentPosts = data.slice(indexOfFirstList, indexOfLastList);

        return currentPosts
    }
}