import React from 'react';
import CountUp from "react-countup";
import {PropsStatisticsCard} from '../../Types'


const StatisticsCard: React.FC<PropsStatisticsCard> = ({
                                                           classCol,
                                                           color,
                                                           title,
                                                           description,
                                                           suffix,
                                                           otherChildren,
                                                           open,
                                                           clicked
}) => {

    return (
        <div className={`${classCol}`}>
            <div className={`rounded`} style={{backgroundColor: color}}>
                {
                    (!otherChildren) ? (
                        <div className='p-5'>
                            <div className={`fw-bold text-white fs-8`}>{title}</div>
                            <div className={`text-white fw-bolder fs-2 mb-2 mt-5`}>
                                <CountUp start={0} end={+description} suffix={(suffix) ? suffix : ""} duration={1.5} />
                            </div>
                        </div>
                    ) : (
                        <div className='row p-5'>
                            <div className="col-7">
                                <div className={`fw-bold text-white fs-8`}>{title}</div>
                                <div className={`text-white fw-bolder fs-2 mb-2 mt-5`}>{description}</div>
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-5">
                                        <div className={`fw-bold text-inverse-danger fs-8`}>Ouverts</div>
                                        <div className={`text-inverse-danger fw-bolder fs-2 mb-2 mt-5`}>
                                            <CountUp start={0} end={(open) ? +open : 0} suffix={(suffix) ? suffix : ""} duration={1.5} />
                                        </div>
                                    </div>
                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                        <span className="bullet bullet-vertical text-gray-400 h-50px w-1px" />
                                    </div>
                                    <div className="col-5">
                                        <div className={`fw-bold text-inverse-danger fs-8`}>Cliqués</div>
                                        <div className={`text-inverse-danger fw-bolder fs-2 mb-2 mt-5`}>
                                            <CountUp start={0} end={(open) ? +open : 0} suffix={(suffix) ? suffix : ""} duration={1.5} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default StatisticsCard