import React, { useContext, useEffect, useState } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import routes from './routes.json'
import {ProductContext, ProductReturn} from '../../../../app/GeneraleVariables'
import {useUser} from '../../../../app/modules/context/types'

interface MenuConfig {
    title: string;
    icon: string;
    createUrl: string;
    activeUrl: string;
}

interface MenuRoute {
    primary: string;
    secondary?: { to: string; title: string }[];
}

interface Routes {
    CallTracking: MenuRoute;
    CallTrackingStatistics: MenuRoute;
    WebForms: MenuRoute;
    Mailing: MenuRoute;
    Statistics: MenuRoute;
    Contacts: MenuRoute;
    Report: MenuRoute;
    [key: string]: MenuRoute;
}

interface AccountStatuses {
    CallTracking: boolean;
    Statistics: boolean;
    WebForms: boolean;
    Mailing: boolean;
}

const menuConfig: { [key: string]: MenuConfig } = {
    CallTracking: {
        title: "Journal des appels",
        icon: "/media/icons/duotune/communication/com014.svg",
        createUrl: "/creer-compte-calltracking",
        activeUrl: "/call-tracking/journal-appels"
    },
    WebForms: {
        title: "Vos formulaires",
        icon: "/media/icons/duotune/general/gen005.svg",
        createUrl: "/creer-compte-webformulaires",
        activeUrl: "/web-formulaires/tableau-de-bord-wf"
    },
    Mailing: {
        title: "Vos campagnes e-mails",
        icon: "/media/icons/duotune/communication/com002.svg",
        createUrl: "/creer-compte-mailing",
        activeUrl: "/mailing"
    },
    Statistics: {
        title: "Référencement naturel",
        icon: "/media/icons/duotune/general/gen032.svg",
        createUrl: "/creer-compte-statistiques",
        activeUrl: "/statistiques/s-seo"
    },
}

const checkProductStatus = (productsStatus: ProductReturn[], productName: string): boolean => {
    return productsStatus.some(item => item.name === productName && item.status);
}


export function AsideMenuMain() {
    const productsStatus = useContext<ProductReturn[]>(ProductContext)
    const {googleAnalytics} = useUser()

    const [accountStatuses, setAccountStatuses] = useState<AccountStatuses>({
        CallTracking: false,
        Statistics: false,
        WebForms: false,
        Mailing: false
    })

    useEffect(() => {
        setAccountStatuses({
            CallTracking: checkProductStatus(productsStatus, 'Calltraking'),
            Statistics: checkProductStatus(productsStatus, 'Statistiques'),
            WebForms: checkProductStatus(productsStatus, 'Webformulaires'),
            Mailing: checkProductStatus(productsStatus, 'Mailing')
        })
    }, [productsStatus])

    return (
        <>
            <AsideMenuItem
                to="/dashboard"
                icon="/media/icons/duotune/art/art002.svg"
                title="Tableau de bord"
                fontIcon="bi-app-indicator"
            />

            {Object.entries(menuConfig).map(([key, config]) => {
                const route = (routes as Routes)[key];
                const isActive = accountStatuses[key as keyof AccountStatuses];

                return isActive ? (
                    key === 'Mailing' ? (
                        <AsideMenuItemWithSub
                            key={key}
                            to={route.primary}
                            title={config.title}
                            icon={config.icon}
                            fontIcon="bi-layers"
                        >
                            {route.secondary?.map((item, index) => (
                                <AsideMenuItem
                                    to={`${route.primary}${item.to}`}
                                    title={item.title}
                                    hasBullet={true}
                                    key={index}
                                />
                            ))}
                        </AsideMenuItemWithSub>
                    ) : (
                        <AsideMenuItem
                            key={key}
                            to={config.activeUrl}
                            icon={config.icon}
                            title={config.title}
                            fontIcon="bi-layers"
                        />
                    )
                ) : (
                    <AsideMenuItem
                        key={key}
                        to={config.createUrl}
                        icon={config.icon}
                        title={config.title}
                        fontIcon="bi-layers"
                    />
                )
            })}

            <AsideMenuItem
                to={routes.Contacts.primary}
                icon="/media/icons/duotune/communication/com013.svg"
                title="Contacts"
                fontIcon="bi-layers"
            />

            {
                googleAnalytics
                    ? <AsideMenuItem
                        to={routes.Report.primary}
                        icon="/media/icons/duotune/general/gen062.svg"
                        title="Rapport"
                        fontIcon="bi-layers"
                    /> : <></>
            }

            <AsideMenuItem
                to={"/sav/accueil"}
                icon="/media/icons/duotune/files/fil003.svg"
                title="SAV"
                fontIcon="bi-layers"
            />

        </>
    )
}
