import React, {FC} from 'react'
import {useReport} from '../context/ReportProvider'
import {ChildrenInterface} from '../../../../global'
import {origineGeographiqueVille, seoTopPages, topMotsCles} from '../../../modules/products/statistiques/ts'

const SeoStats = () => {

    const {seo: {geographicOriginByCity, mostViewedPage, byChannels}} = useReport()

    return (
        <>
            <div className="col-4">
                <StatsItem title={'Origines géographiques des visiteurs'} array={geographicOriginByCity.slice(0, 5)}/>
            </div>
            <div className="col-4">
                <StatsItem title={'Pages les plus consultées'} array={mostViewedPage.slice(0, 5)}/>
            </div>
            <div className="col-4">
                <StatsItem title={'Des canaux'} array={byChannels.slice(0, 5)}/>
            </div>


            {/*<div className="col-lg-6">*/}
            {/*  <div className="card card-flush h-xl-100">*/}
            {/*    <div className="card-header p-5">*/}
            {/*      /!*begin::Title*!/*/}
            {/*      <h3 className="card-title align-items-start flex-column">*/}
            {/*        <span className="card-label fw-bold text-gray-800">Top 5</span>*/}
            {/*        <span className="text-gray-500 mt-1 fw-semibold fs-8">Origines géographiques par ville</span>*/}
            {/*      </h3>*/}
            {/*      /!*  end::Title*!/*/}
            {/*    </div>*/}
            {/*    <div className="card-body p-5">*/}
            {/*      <IsLoading>*/}
            {/*        <>*/}
            {/*          {*/}
            {/*            geographicOriginByCity.slice(0, 5).map(({city}, index) => (*/}
            {/*              <div key={index}>*/}
            {/*                <div className="d-flex flex-stack">*/}
            {/*                  <div className="d-fl  ex align-items-center me-5">*/}
            {/*                    <div className="text-gray-800 fw-bold fs-8">*/}
            {/*                      {city}*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*                <SeparatorComponent index={index} length={geographicOriginByCity.length}/>*/}
            {/*              </div>*/}
            {/*            ))*/}
            {/*          }*/}
            {/*        </>*/}
            {/*      </IsLoading>*/}

            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="col-lg-6">*/}
            {/*  <div className="card card-flush h-xl-100">*/}
            {/*    <div className="card-header p-5">*/}
            {/*      /!*begin::Title*!/*/}
            {/*      <h3 className="card-title align-items-start flex-column">*/}
            {/*        <span className="card-label fw-bold text-gray-800">Top 5</span>*/}
            {/*        <span className="text-gray-500 mt-1 fw-semibold fs-8">Pages les plus consultées</span>*/}
            {/*      </h3>*/}
            {/*      /!*  end::Title*!/*/}
            {/*    </div>*/}
            {/*    <div className="card-body p-5">*/}
            {/*      <IsLoading>*/}
            {/*        <>*/}
            {/*          {*/}
            {/*            mostViewedPage.slice(0, 5).map(({path, page}, index) => (*/}
            {/*              <div key={index}>*/}
            {/*                <div className="d-flex flex-stack">*/}
            {/*                  <div className="d-flex align-items-center me-5">*/}
            {/*                    <a href={`https://${path}`}*/}
            {/*                       className="text-dark text-hover-primary fw-bolder d-block mb-1 fs-8"*/}
            {/*                       target="_blank">{page}</a>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*                <SeparatorComponent index={index} length={mostViewedPage.length}/>*/}
            {/*              </div>*/}
            {/*            ))*/}
            {/*          }*/}
            {/*        </>*/}
            {/*      </IsLoading>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
        </>
    )
}

interface InterfaceSeparatorComponent {
    index: number
    length: number
}

const SeparatorComponent: FC<InterfaceSeparatorComponent> = ({index, length}) => {

    const show = length > 5 ? 4 : length - 1
    if (index === show) return <></>
    return <div className="separator separator-dashed my-4"></div>
}

export {SeoStats}


const IsLoading: FC<ChildrenInterface> = ({children}) => {

    const {loadingStatus} = useReport()
    const array = Array.from(Array(5).keys())

    return (
        <>
            {
                loadingStatus === 'fulfilled' ? (
                    array.map((item, index) => (
                        <div className="w-100" key={index}>
                            <div className="bg-light-dark h-20px"></div>
                            <SeparatorComponent index={index} length={array.length}/>
                        </div>
                    ))
                ) : children
            }
        </>
    )
}

type StatsItemProps = {
    title: string
    array: origineGeographiqueVille[] | seoTopPages[] | topMotsCles []
}

const StatsItem: FC<StatsItemProps> = ({title, array}) => {

    const fakeArray = Array.from(Array(5).keys())

    return (
        <>
            <div className="d-flex flex-column mb-4">
                <span className="fs-4 fw-bold text-white">Top 5</span>
                <span className="fs-5 fw-bold text-white">{title}</span>
            </div>

            {
                !array.length ? (
                    <>
                        {
                            fakeArray.map((index) => (
                                <div className="card mb-3" key={index}>
                                    <div className="card-body py-5 ">
                                        <div className="bg-light-dark h-20px"></div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                ) : (
                    <>
                        {array.map((item, index) => {

                            let displayText: string
                            if ((item as origineGeographiqueVille).city !== undefined) {
                                displayText = (item as origineGeographiqueVille).city
                            } else if ((item as seoTopPages).path !== undefined) {
                                displayText = (item as seoTopPages).path
                            } else if ((item as topMotsCles).keyword !== undefined) {
                                displayText = (item as topMotsCles).keyword
                            } else {
                                displayText = 'Unknown' // Fallback in case of unexpected data
                            }

                            return (
                                <div className="card mb-3" key={index}>
                                    <div className="card-body py-5 ">

                                        <span className="text-primary">{displayText}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )

            }

        </>
    )
}