import ExtractDate from "./ExtractDate";

/**
 * @param products
 * @param productsList
 * @param cleverPhone
 *
 * @return [{productName, productStatus, productDate}]
 */

type PropsProduct = {
    name: string;
    status: number;
    date: string;
    description: string;
}

export default function ProductsData(
    {products, productsList}: { products: any[], productsList: any[] },
) {

    let product: PropsProduct[] = [];

//  TODO:   productsNameList = list products name
//     for (let i = 0; i < productsList.length; i++) {
//
//         productsNameList.push(productsList[i]);
//     }

//  TODO:   productNameStatus = object of each product
     for (let i = 0; i < products.length; i++) {

        for (let j = 0; j < productsList.length; j++) {

            if (products[i][productsList[j].name + 'Date'] !== null) {

                let dateTime = ExtractDate( {dateTime: products[i][productsList[j].name + 'Date'] , separator: ' ', localeString: true});

                let [dayString, longMonth, yearString] = dateTime;

                product.push({
                    name: productsList[j].name,
                    status: products[i][productsList[j].name],
                    date: `${dayString}, ${longMonth} ${yearString}`,
                    description: productsList[j].description
                });
            } else {

                product.push({
                    name: productsList[j].name,
                    status: products[i][productsList[j].name],
                    date: '',
                    description: productsList[j].description
                });
            }
        }
    }
    return product;
}