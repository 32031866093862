import React, {FC, useState} from 'react'

interface RatingsInterface {
    ratingsLength: number
    ratingSize: string
    formik: any
    fieldName: string
}

const Ratings: FC<RatingsInterface> = ({formik, fieldName, ratingsLength, ratingSize}) => {

    const [hover, setHover] = useState<number>(0)
    return (
        <>
            <div className="rating d-flex justify-content-center align-items-center mb-5">
                {[...Array(ratingsLength)].map((star, index) => {

                    const isChecked = index + 1 <= (hover || formik.values.rating);

                    return (
                        <div
                            className={`rating-label me-2 ${isChecked ? 'checked' : ''}`}
                            key={index}
                            onMouseEnter={() => setHover(index + 1)}
                            onMouseLeave={() => setHover(0)}
                            onClick={() => {
                                formik.setFieldValue(fieldName, index + 1)
                            }}
                        >
                            <i className={`bi bi-star-fill ${ratingSize}`}></i>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export {Ratings}