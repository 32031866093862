import {localStorageKey, localStorageValue} from '../all-pages'

/**
 *  true : redirect to HeaderUserMenu
 *  false: redirect to login
 */


function RedirectPages() {

    if ( (localStorage.getItem(localStorageKey) !== null && localStorage.getItem(localStorageKey) == localStorageValue)) {

        return true;
    } else if ( (localStorage.getItem(localStorageKey) === null || localStorage.getItem(localStorageKey) != localStorageValue)) {

        return false;
    } else {

        return false;
    }
}

export default RedirectPages;