import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from "../../../../../../../../_metronic/helpers";
import AllTemplates from "../../../../../../functions/mailing/get/campaign/AllTemplates";
import {InterfaceTemplateCreate, InterfaceTemplateDefault} from "../contents/components/template/TypeTemplates";
import {TemplateDrawer} from "../contents/components/settings/Template";


const DrawerCampaign: FC = () => {

    const [dataTemplateGallery, setDataTemplateGallery] = useState<InterfaceTemplateDefault[]>([]);
    const [dataTemplateCreate, setDataTemplateCreate] = useState<InterfaceTemplateCreate[]>([]);

    useEffect(() => {

        AllTemplates({setDataTemplateGallery, setDataTemplateCreate});
    }, []);

    return (
        <div
            id='kt_drawer_campaign'
            className='bg-white'
            data-kt-drawer='true'
            data-kt-drawer-name='campaign'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'300px', 'sm': '600px', 'md': '500px', 'lg': '800px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_drawer_campaign_toggle'
            data-kt-drawer-close='#kt_drawer_chat_close'
        >
            <div className='card w-100 rounded-0' id='kt_drawer_campaign'>
                <div className='card-header pe-5' id='kt_drawer_campaign_header'>
                    <div className='card-title fw-bold text-gray-600'>
                        <div className='d-flex justify-content-center flex-column me-3'>
                            Templates
                        </div>
                    </div>

                    <div className='card-toolbar'>
                        <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                        </div>
                    </div>
                </div>
                <div className='card-body' id='kt_explore_body'>
                    {/* begin::Content */}
                    <div
                        id='kt_explore_scroll'
                        className='scroll-y me-n5 pe-5'
                        data-kt-scroll='true'
                        data-kt-scroll-height='auto'
                        data-kt-scroll-wrappers='#kt_explore_body'
                        data-kt-scroll-dependencies='#kt_explore_header, #kt_explore_footer'
                        data-kt-scroll-offset='5px'
                    >
                        <div className="row mb-3">
                            {
                                dataTemplateGallery.map((item, index) => (
                                    <TemplateDrawer key={index} item={item} category="d"  />
                                ))
                            }
                        </div>
                        <div className="row">
                            {
                                dataTemplateCreate.map((item, index) => (
                                    <TemplateDrawer key={index} item={item} category="p"  />
                                ))
                            }
                        </div>
                    </div>
                    {/* end::Content */}
                </div>

            </div>
        </div>
    )

}
export {DrawerCampaign}
