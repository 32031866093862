import {KTSVG} from '../../../../_metronic/helpers'
import React from 'react'
import {PropsWindowSize} from '../Types'
import {UseWindowSize} from '../hooks'
import {useHistory} from 'react-router-dom'

export const GoBackBtn = () => {

    const windowSize: PropsWindowSize = UseWindowSize()
    const history = useHistory()

    const handleClick = () => {
        history.goBack()
        // const lastEntry = goBack[goBack.length - 2]
        // console.log(lastEntry)
        // if (lastEntry) history.push(lastEntry.url)
    }

    return (
        <>
            <div
               className="btn btn-sm btn-flex bg-light-primary text-primary fw-bolder "
               onClick={handleClick}

            >
                <KTSVG path="/media/icons/duotune/arrows/arr002.svg"
                       className={`svg-icon-3 svg-icon-primary ${(windowSize.innerWidth >= 576) ? 'me-2' : ''}`}
                />
                {(windowSize.innerWidth >= 576) ? 'Retour' : ''}
            </div>
        </>
    )
}