import React, {FC} from 'react'
import {ListsWidget5} from '../../widgets'

const DrawerContacts: FC = () => (
    <div
        id='kt_drawer_contacts'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='contacts'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_contatcs_toggle'
        data-kt-drawer-close='#kt_drawer_contacts_close'
    >
        <div className="row">
            <div className='col-xxl-4'>
                <ListsWidget5 className='card-xxl-stretch'/>
            </div>
        </div>
    </div>
)

export {DrawerContacts}
