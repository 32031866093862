import {PropsChartPie,
    PropsMultiplesStroke,
} from '../../../modules/products/call-traking/components/all-pages'
import {labelColor, successColor} from '../../../modules/all-pages'

interface Interface {
    chartHeight?: string;
    baseColor?: string | string[];
    lightColor?: string
    strokeColor?: string;
    chartColor?: string
    secondaryColor?: string;
    borderColor?: string
}
interface InterfaceArea extends Interface{
    colors? : string[]
}
interface InterfaceBar extends Interface{}

interface InterfaceLabelsDates {
    currentDate: string
    afterDate: string
}

function LabelsDates({afterDate, currentDate}: {afterDate: any; currentDate: any}) {

    if (afterDate) {
        const [day, month, year] = afterDate.split('-'),
            afterValueDate =  new Date(+year, month - 1, day - 1);

        const y = afterValueDate.getFullYear()
        const m = afterValueDate.getMonth() + 1
        const d = afterValueDate.getDate()

        const to = `${d}-${m}-${y}`
        if (currentDate === to) return currentDate
        return `[${currentDate} | ${to}]`
    } else {
        return `[${currentDate} | ...[`
    }

}


export class Charts {

    /**
     * Chart Bar
     * @param chartHeight
     * @param strokeColor
     * @param baseColor
     * @param secondaryColor
     * @param borderColor
     * @constructor
     */
    public static OptionsBar ({chartHeight, strokeColor, baseColor, secondaryColor, borderColor}: InterfaceBar) {

        return {
            series: [],
            chart: {
                fontFamily: 'inherit',
                type: 'bar',
                height: chartHeight,
                toolbar: {
                    show: false,
                },
                selection: {
                    enabled: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                formatter: function (val: number) {
                    return val ? val : ''
                },
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    colors: ['#fff']
                },
                background: {
                    enabled: true,
                    foreColor: '#b9bcca',
                    padding: 5,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 1,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: [],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                show: true,
                showAlways: true,
                showForNullSeries: true,
                seriesName: undefined,
                opposite: false,
                reversed: false,
                min: 0,
                // max: (value: any) => { return value + 1; },
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                },
                axisBorder: {
                    show: true,
                    color: '#95afbb',
                },
            },
            fill: {
                type: 'solid',
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val: any) {
                        return val
                    },
                },
                x: {
                    format: "dd MMM yyyy",
                    formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: any) {

                        const currentDate = w.globals.labels[dataPointIndex],
                            afterDate = w.globals.labels[dataPointIndex + 1]

                        return LabelsDates({currentDate, afterDate})
                    }
                }
            },
            colors:["#57bb84", "#0066ff"
                // (value: chartValue) => {
                //     if (value.value < averageFormsReceived) return dangerColor;
                //     else if (value.value == averageFormsReceived) return infoColor;
                //     else return successColor;
                // },
                // (value: chartValue) => {
                //
                //     if (value.value < averageFormsProcessed) return dangerColor;
                //     if (value.value < averageFormsProcessed) return infoColor;
                //     else return successColor;
                // },
            ],
            grid: {
                padding: {
                    top: 10,
                },
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        }
    }

    /**
     * Chart Area
     * One Stroke
     * @param chartHeight
     * @param baseColor
     * @constructor
     */
    public static OptionsArea({chartHeight, colors}: InterfaceArea) {

        return {
            chart: {
                height: chartHeight,
                type: 'line', // area
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                },
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 2,
                    blur: 4,
                    opacity: 0.2,
                }
            },
            stroke: {
                curve: 'smooth',
                width: 5
            },
            markers: {
                size: 5,
                strokeWidth: 0,
                hover: {
                    size: 6
                }
            },
            grid: {
                show: true,
                padding: {
                    bottom: 0
                }
            },
            xaxis: {
                tooltip: {
                    enabled: false
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 20
            },
            colors: colors,
            tooltip: {
                x: {
                    format: "dd MMM yyyy",
                    formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: any) {

                        const currentDate = w.globals.categoryLabels[dataPointIndex],
                            afterDate = w.globals.categoryLabels[dataPointIndex + 1]

                        return LabelsDates({currentDate, afterDate})
                    }
                }
            }
        }
    }



    /**
     * Chart Multiple Stroke
     * @param chartHeight
     * @param colors
     */
    public static OptionsChartAreaMultiplesStroke ({chartHeight, colors}: PropsMultiplesStroke) {

        return {
            chart: {
                height: chartHeight,
                type: 'area',
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 2,
                    blur: 5,
                    opacity: .2,
                    color: successColor
                },
                offsetX: 0,
                offsetY: 0,
                sparkline: {
                    enabled: false,
                },
                stacked: false,
                stackType: 'normal',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        customIcons: []
                    },
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    },
                    autoSelected: 'zoom'
                },
            },
            stroke: {
                curve: 'smooth',
                width: 5,
            },
            markers: {
                size: 4,
                strokeWidth: 0,
                hover: {
                    size: 6
                }
            },
            grid: {
                show: false,
                padding: {
                    bottom: 0
                }
            },
            legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'top',
                horizontalAlign: 'center',
                floating: false,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    // strokeColor: undefined,
                    // fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: true,
                inverseOrder: true,
                custom: undefined,
                fillSeriesColor: false,
                theme: 'light',
                style: {
                    fontSize: '12px',
                    fontFamily: undefined
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                x: {
                    show: true,
                    format: 'dd MMM',
                    formatter: undefined,
                },
                y: {
                    formatter: function (val: number) {
                        return val
                    },
                },
                z: {
                    formatter: undefined,
                    title: 'Size: '
                },
                marker: {
                    show: true,
                },
                items: {
                    display: 'flex',
                },
                fixed: {
                    enabled: false,
                    position: 'topLeft',
                    offsetX: 40,
                    offsetY: 0,
                },
            },
            plotOptions: {},
            dataLabels: {
                enabled: false,
                enabledOnSeries: undefined,
                formatter: function (val: any) {
                    return val
                },
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    colors: ['#fff']
                },
                background: {
                    enabled: true,
                    foreColor: '#b9bcca',
                    padding: 5,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 1,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            // fill: {
            //     type: 'solid',
            //     opacity: 1,
            //     colors: undefined
            // },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            xaxis: {
                type: 'category',
                categories: [0],
                tickAmount: undefined,
                tickPlacement: 'on',
                min: undefined,
                max: undefined,
                range: undefined,
                floating: false,
                decimalsInFloat: undefined,
                overwriteCategories: undefined,
                position: 'bottom',
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: undefined,
                    formatter: undefined,
                    datetimeUTC: true,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                },
                axisBorder: {
                    show: true,
                    color: '#78909C',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#78909C',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                },

                title: {
                    text: 'Période',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                crosshairs: {
                    show: true,
                    width: 1,
                    position: 'back',
                    opacity: 0.9,
                    stroke: {
                        color: '#b6b6b6',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'solid',
                        color: '#B1B9C4',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
                tooltip: {
                    enabled: false,
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                        fontSize: '0',
                        fontFamily: '0',
                    },
                },
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                },
                title: {
                    rotate: -90,
                    offsetX: -10,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetX: 5,
                },
            },
            colors: colors
        }
    }

    /**
     * Chart Pie
     * @param label
     * @param colors
     */
    public static OptionChartPie ({label, colors}: PropsChartPie) {

        return {
            chart: {
                type: 'pie',
                width: 500,
                height: 500,
            },
            legend: {
                position: "bottom"
            },
            fill: {
                opacity: .7
            },
            stroke: {
                width: 2,
                colors: ["#fff"]
            },
            yaxis: {
                show: false
            },
            labels: label,
            responsive: [{
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }],
            colors: colors

        }
    }


}
