import React from "react";

export default function ErrorCatch(
    setLoading: any,
    setSubmitting: any,
    setStatus:any,
    msgSuccess: string,
    msgError: string,
    setRedirect: any,
    setShowForm: any,
    setColor: any,
) {

    setSubmitting(false);
    setStatus(msgError);

    if (setLoading !== null) { setLoading(false); }

    if (setColor !== null) { setColor('danger'); }
    if (setRedirect != null) { setRedirect(false); }
    if (setShowForm != null) { setShowForm(true); }
}
