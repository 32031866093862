import React, {FC, useState} from 'react'
import {Link, useHistory} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import clsx from "clsx";
import * as Yup from 'yup';
import {useFormik} from "formik";
import SVG from "react-inlinesvg";
import EmailExist from "../../../modules/functions/post/forgot-password/EmailExist";

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Format d\'e-mail incorrect')
        .min(12, 'Minimum 12 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('E-mail est obligatoire')
});
const initialValues = {
    email: '',
}

const ForgotPassword: FC = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [color, setColor] = useState('');

    const formik = useFormik({

        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {

            // setLoading(true);
            setTimeout(() => {

                let dataObject = {
                    email: values.email,
                };

                EmailExist(
                    {
                        data: dataObject,
                        setSubmitting,
                        setStatus,
                        setColor,
                        msgError: 'Aucun utilisateur trouvé',
                        msgSuccess: 'Nous avons envoyé votre lien de réinitialisation de mot de passe par e-mail',
                    },
                );
            }, 100);
        }
    });

    return (
        <>
            <div
                className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            >
                {/* begin::Content */}
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                    {/* begin::Logo */}
                    <div className='mb-10'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo-WF_2.png')} className='h-75px' />
                    </div>
                    {/* end::Logo */}
                    {/* begin::Wrapper */}
                    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>

                        <form
                            className='form w-100 py-5'
                            noValidate
                            id='kt_forgot_password_form'
                            onSubmit={formik.handleSubmit}
                        >
                            {formik.status ? (

                                <div className={`alert alert-${color} d-flex align-items-center p-5 mb-10`}>
                                <span className={`svg-icon svg-icon-2hx svg-icon-${color} me-2`}>
                                    <span className={`svg-icon svg-icon-2hx svg-icon-${color} me-4`}>
                                        {(color === 'danger') && <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen050.svg')}/>}
                                        {(color === 'success') && <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen048.svg')}/>}
                                    </span>
                                </span>
                                    <div className="d-flex flex-column">
                                        <span>{formik.status}</span>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* begin::E-mail */}
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
                                <input
                                    placeholder='Email'
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                    )}
                                    type='email'
                                    autoComplete='off'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container my-1'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className="text-danger p-2">{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::E-mail */}

                            {/* begin::Action */}
                            <div className='text-center'>
                                <button
                                    type='submit'
                                    id='kt_forgot_password_submit'
                                    className='btn btn-lg btn-primary w-100 mb-5'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    <span className='indicator-label'>Envoyer</span>
                                    {/*{!loading && <span className='indicator-label'>Continue</span>}*/}
                                    {/*{loading && (*/}
                                    {/*    <span className='indicator-progress' style={{display: 'block'}}>*/}
                                    {/*        Please wait...*/}
                                    {/*        <span className='spinner-border spinner-border-sm align-middle ms-2' />*/}
                                    {/*    </span>*/}
                                    {/*)}*/}
                                </button>
                            </div>
                            {/* end::Action */}
                        </form>
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Content */}

            </div>
        </>
    )
}

export {ForgotPassword}
