import React, {FC} from 'react'
import {Input} from '../Input'

type PropsAdd = {
    formik: any
}

const Add: FC<PropsAdd> = ({formik}) => {

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-6">
                    {/*   firstName   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>Prénom</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'first_name'} placeholder={'Prénom'}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">

                    {/*   lastName   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label
                            className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>Nom</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'last_name'} placeholder={'Nom'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    {/*   address   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>Adresse</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'street'} placeholder={'Adresse'}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">

                    {/*   company   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>Entreprise</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'companyName'} placeholder={'Entreprise'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6">
                    {/*   address   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>E-mail</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'email'} placeholder={'E-mail'}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">

                    {/*   company   */}
                    <div className='row mb-0 mb-md-3 mb-lg-6'>
                        <label className='col-lg-3 d-flex align-items-center col-form-label fw-bold fs-7'>Téléphone</label>

                        <div className='col-lg-9 fv-row'>
                            <Input formik={formik} type={'text'} name={'number'} placeholder={'Téléphone'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Add}