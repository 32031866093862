import React from 'react'
import {Card2} from './component/Card2'
import {useReport} from './context/ReportProvider'
import {UseWindowSize} from '../../modules/all-pages'

const styles = {
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridTemplateRows: "1fr",
        gridGap: 10
    },
    gridMd: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    gridSm: {
        gridTemplateColumns: "repeat(2, 1fr)",
    }
};
const Conversion = () => {

    const {callReceived, formsReceived, campagne: {countMessages, countDataContacts}} = useReport()
    const {innerWidth} = UseWindowSize()

    const data = [
        {title: 'conversions', stats: null, img: null},
        {title: 'appels', stats: callReceived.generalAppelRecu, img: '/media/icons/duotune/communication/com019.svg'},
        {title: 'cliques', stats: callReceived.click_sur_tel, img: '/media/icons/duotune/coding/cod0011.svg'},
        {title: 'formulaires', stats: formsReceived, img: '/media/icons/duotune/general/gen061.svg'},
        {title: 'contacts', stats: countDataContacts, img: '/media/icons/duotune/communication/com020.svg'},
    ]

    const getResponsiveStyles = () => {
        if (innerWidth <= 480) {
            return {...styles.grid, ...styles.gridSm}
        } else if (innerWidth <= 768) {
            return {...styles.grid, ...styles.gridMd}
        } else {
            return styles.grid
        }
    }

    return (
        <div className="mt-6 mb-10" style={getResponsiveStyles()}>
            {
                data.map(({title, stats, img}, index) => (
                    <div className="" key={index}>
                        <Card2 title={title} stats={stats} svgIcon={img ? {type: 'duotune', path: img} : null} />
                    </div>
                ))
            }

        </div>
    )
}
export {Conversion}