import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import React, {FC, useState} from 'react'
import {useFormik} from 'formik'
import {RequestAProduct} from '../../functions/post'
import {Card} from './cards'
import {PropsCreateProduct} from '../Types'

const CreateProduct = ({name, description, treaty}: PropsCreateProduct) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [treaty1, setTreaty1] = useState<boolean>(false);

  const initialValues = {
    message: name,
    products: name,
  }

  const formik = useFormik({

    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {

      setLoading(true);
      setSubmitting(false);

      setTimeout(() => {
        RequestAProduct({values, setStatus, setLoading, setDisable, setTreaty1})
      }, 300)
    },
  });

  return (
    <>
      <Card id={`create_account_${name.toLowerCase()}`} title={name}>
        {formik.status ? (
          <div className={`alert alert-success d-flex align-items-center p-2 mb-7`}>
            <span className={`svg-icon svg-icon-2hx svg-icon-success mx-2`}>
                <span className={`svg-icon svg-icon-2hx svg-icon-success me-4`}>
                     <SVG src={toAbsoluteUrl(`/media/icons/duotune/general/gen048.svg`)}/>
                </span>
            </span>
            <div className="d-flex flex-column">
              <span>{formik.status}</span>
            </div>
          </div>
        ) : (
          ''
        )}
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row mb-8'>
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
          <div className="row">
            <div className="col-4 offset-8 d-flex justify-content-end align-items-center">
              {
                (treaty === 1 || treaty === 0 || treaty1)
                    ? (
                        <div className="d-flex justify-content-between align-items-center fs-7 text-info fw-bolder demandIcon">
                          <div className={""}>Traitement en cours</div>
                          <KTSVG path='/media/icons/duotune/abstract/abs014.svg'
                                 className='svg-icon-1 svg-icon-info mx-2'
                          />
                        </div>
                    )
                    : (treaty === -1) ? (
                        <Demand loading={loading} disable={disable} />
                    )
                    :(
                        <Demand loading={loading} disable={disable} />
                    )
              }

            </div>

          </div>
        </form>
      </Card>
    </>
  )
}

type PropsDemand = {
  loading: boolean;
  disable: boolean;
}

const Demand: FC<PropsDemand> = ({loading, disable}) => {

  return (
      <button type='submit' className='btn btn-sm btn-flex btn-light-primary btn-active-light-primary fw-bolder'
              disabled={disable}
      >
        {!loading && (
            <>
              <KTSVG path='/media/icons/duotune/general/gen016.svg'
                     className='svg-icon-2 svg-icon-gray-500 me-2'
              />
              Demande
            </>
        )}
        {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
                                S'il vous plaît, attendez...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                              </span>
        )}
      </button>
  )
}

export {CreateProduct}