import React, {memo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import CountUp from 'react-countup'
import {Link} from 'react-router-dom'

type Props = {
    bg: string
    iconPath: string
    body: {
        total: number,
        text: string,
    }
    to?: string
    // footer: {start: string, end: string} | string
}

const ChartDashboard1: React.FC<Props> = ({bg, iconPath, body, to}) => {

    return (
        <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
             style={{backgroundColor: `${bg}bf`}}
        >
            {/*begin::Card Header*/}
            <div className="card-header p-5">
                {/*begin::Icon*/}
                <div className="d-flex flex-center rounded-circle h-40px w-40px"
                     style={{border: '1px dashed rgba(255, 255, 255, .6)', backgroundColor: bg}}
                >
                    <KTSVG path={iconPath}
                           className="svg-icon svg-icon-2 svg-icon-white"
                    />
                </div>
                {/*end::Icon*/}
                {
                    to && (
                        <div className="card-toolbar m-0 d-flex align-items-start">
                            <Link to={to} className="btn btn-sm fw-bold text-white" style={{backgroundColor: bg}}>
                                Voir plus
                            </Link>
                        </div>
                    )
                }

            </div>
            {/*end::Card Header*/}

            {/*begin::Card Section1*/}
            <div className="card-body d-flex align-items-end p-5">
                {/*begin::Info*/}
                <div className="d-flex flex-column">
                    <span className="fs-2 text-white fw-bold me-4">
                        <CountUp end={body.total === -1 ? 0 : body.total} duration={1.5}/>
                    </span>
                    <div className="fw-bold fs-6 text-white">
                        <span className="d-block">{body.text}</span>
                    </div>
                </div>
                {/*end::Info*/}
            </div>
            {/*end::Card Section1*/}

            {/*begin::Card Footer*/}
            {/*<div className="card-footer"*/}
            {/*     style={{border: '1px dashed rgba(255, 255, 255, .6)', backgroundColor: 'rgba(0, 0, 0, 0.15)'}}*/}
            {/*>*/}
            {/*    /!*begin::Progress*!/*/}
            {/*    <div className="fw-bold text-white py-2">*/}
            {/*        {*/}
            {/*            typeof footer === 'string'*/}
            {/*                ? <span className="fs-1 d-block">{footer}</span>*/}
            {/*                : <>*/}
            {/*                    <span className="fs-1 d-block">{footer.start}</span>*/}
            {/*                    <span className="fs-1 d-block">{footer.end}</span>*/}
            {/*                </>*/}
            {/*        }*/}

            {/*        /!*<span className="opacity-50">Problems Solved</span>*!/*/}
            {/*    </div>*/}
            {/*    /!*end::Progress*!/*/}
            {/*</div>*/}
            {/*end::Card Footer*/}
        </div>
    )
}

export default memo(ChartDashboard1)
