import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {rotate_0, rotate_180} from '../../Variables'
import {PropsSubCard} from '../../Types'

const SubCard: FC<PropsSubCard> = ({id, title, children}) => {

  const [toolbarIcon, setToolbarIcon] = useState<boolean>(true);

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header bg-light-info border-0 cursor-pointer min-h-50px'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target={`#kt_${id}_details`}
          aria-expanded='true'
          aria-controls={`kt_${id}_details`}
          onClick={() => setToolbarIcon(!toolbarIcon)}
        >
          <div className='card-title'>
            <h3 className='fw-bolder fw-bolder text-gray-800 fs-7'>{title}</h3>
          </div>
          <div className="card-toolbar ">
            <div className='btn btn-icon w-auto px-0' style={toolbarIcon ? rotate_0 : rotate_180}>
              <KTSVG path={'/media/icons/duotune/arrows/arr073.svg'}
                     className={'svg-icon-2 svg-icon-info'}
              />
            </div>
          </div>
        </div>

        <div id={`kt_${id}_details`} className='collapse show'>
          <div className="card-body p-5">
            {
              children
            }
          </div>
        </div>
      </div>
    </>
  )
}

export {SubCard}