import React, {Dispatch, SetStateAction} from 'react'
import axios from "axios";
import ErrorCatch from "../ErrorCatch";
import {API_URL, localStorageValue} from '../../all-pages'
import {PropsPersonalInformation} from '../../profile/components/TypesPropsPersonalInformation'

/**
 * function PostUser
 * @param BaseUrl
 * @param id
 * @param dataForm
 */

type Props = {
    BaseUrl: string | undefined,
    id: string,
    dataForm: object,
}
export default async function PostUser( {
                                      BaseUrl,
                                      id,
                                      dataForm,
                                  } : Props
) {

    const formData = new FormData();

    for (const [key, value] of Object.entries(dataForm)) {

        formData.append(key, value);
    }
     const data = await axios({

            method: 'POST',
            url: `${API_URL}${BaseUrl}${id}`,
            headers: {
                'Authorization': 'Bearer ' + localStorageValue,
            },
            data: formData,
        })

    return data;
}