import React, {FC} from 'react'
import {useHistory} from 'react-router-dom'
import {PropsCard} from '../../Types'

const Card: FC<PropsCard> = ({id, title, children,}) => {

  const {location} = useHistory();

  const padding = ["/statistiques/s-seo", "/statistiques/s-sea", "/pages/privacy-policy"].includes(location.pathname);

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className={`card-header border-0 ${!"/pages/privacy-policy".includes(location.pathname) ? "" : "px-2 px-sm-10"}`}
        >
          <div className='card-title m-0 '>
            <h3 className={`fw-bolder m-0 fs-5 fs-sm-3`}>{title}</h3>
          </div>
        </div>

        <div className='collapse show'>
          <div className={`card-body border-top ${!padding ? 'px-3' : 'p-4'} p-sm-4 p-md-8`}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export {Card}