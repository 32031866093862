import React from "react";
import axios from "axios";
import ErrorCatch from "../../ErrorCatch";
import SuccessCatch from "../SuccessCatch";
import {API_URL} from '../../../all-pages'

export default function PasswordReset(
    data: object,
    setSubmitting: any,
    setStatus: any,
    setColor: any,
    msgError: string,
    msgSuccess: string,
    setRedirect: any
) {


    const RESET_PASSWORD = process.env.REACT_APP_RESET_PASSWORD;

    axios({

        method: 'POST',
        url: `${API_URL}${RESET_PASSWORD}`,
        headers: { },
        data: data
    })
        .then(function (response) {

            if (response.data.status === 200) {
                SuccessCatch(setSubmitting, setStatus, msgSuccess, setColor);
                setTimeout(() => {
                    setRedirect(true)
                }, 2000)
            }
            if (response.data.status === 500) {

                setRedirect(false)
                ErrorCatch(null, setSubmitting, setStatus, '', msgError, null, null, setColor);
            }
        })
        .catch(function (error) {

            console.log('PasswordReset Error : ', error);
        });
}