import React, {FC} from 'react'

type PropsStatisticsList = {
    mb: string,
    color: string,
    title: string,
    stats: JSX.Element,
}
const StatisticsList: FC<PropsStatisticsList> = ({mb, color, title, stats}) => {
    return (
        <>
            <div className={`d-flex align-items-center ${mb}`}>
                {/* begin::Bullet */}
                <span className={`bullet bullet-vertical  h-30px h-sm-40px bg-${color}`}/>
                {/* end::Bullet */}
                {/* begin::Checkbox */}
                <div className='mx-2 mx-sm-5'/>
                {/* end::Checkbox */}
                {/* begin::Description */}
                <div className='flex-grow-1'>
                    <span className='text-gray-800 fw-bolder fs-8 fs-sm-6 me-2'>
                        {title}
                    </span>
                </div>
                {/* end::Description */}
                <span className={`badge badge-light-${color} fs-8 fw-bolder`}>
                    {stats}
                </span>
            </div>
        </>
    )
}

export {StatisticsList}