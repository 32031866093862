import React, {FC} from 'react'

type Props = {
    thead: JSX.Element,
    tbody: JSX.Element,
}

const Table: FC<Props> = ({thead, tbody}) => {

    return (
        <>
            {/* begin::Table container */}
            <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className="fw-bolder text-muted bg-light">
                            {thead}
                        </tr>
                    </thead>
                    <tbody>{tbody}</tbody>
                </table>
            </div>
        </>
    )
}

export {Table}