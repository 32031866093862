import React, {FC} from "react";
import {Link} from "react-router-dom";
import {InterfaceTemplateCreate, InterfaceTemplateDefault} from "../template/TypeTemplates";

type Props = {
    item: InterfaceTemplateDefault | InterfaceTemplateCreate;
    category: "d" | "p"
}

const API_URL_IMG= process.env.REACT_APP_URL

const Template: FC<Props> = ({item, category}) => {
    return (

        <div className={`col-12 col-sm-6 col-md-4 col-xl-3 mb-3 mb-sm-7 ${((item.image !== null)) ? "" : "h-200px h-sm-300px h-md-350px h-lg-500px overflow-scroll"}`}>
            <div className="card card-custom overlay overflow-hidden h-100">
                <div className="card-body p-0">
                    <div className="overlay-wrapper">
                        {
                            (item.image !== null)
                                ? (<img src={`${API_URL_IMG}${item.image}`} className='w-100 rounded' alt=''/>)
                                : <div dangerouslySetInnerHTML={{__html: (item.content) ? item.content : ""}} className="" />
                        }
                    </div>
                    <div className="overlay-layer bg-dark bg-opacity-50 align-items-center justify-content-center">
                        <div className="d-flex flex-grow-1 flex-center py-5">
                            <Link to={`/mailing/campagne/template/${ (item.image !== null) ? "modeles-galerie" : "mes-modeles"}/${item.id}`}
                                  className='btn btn-light-primary btn-shadow fs-7'
                            >
                                {item.name}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TemplateDrawer: FC<Props> = ({item, category}) => {

    const localStorageKey = "_templateSelected";

    let object = { template_id: `${item.id}-${category}`, name: item.name, content: item.content, }

    const handleLocalStorage = () => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(object))
    }

    return (

        <div className={`col-12 col-sm-4 col-md-3 mb-3 ${((item.image === null)) ? "h-200px overflow-scroll" : ""}`}>
            <div className="card card-custom overlay overflow-hidden position-relative border border-1 border-gray-200 rounded h-200px">
                <div className="card-body p-0">
                    <div className="overlay-wrapper">
                        {
                            (item.image !== null)
                                ? (<img src={`${API_URL_IMG}${item.image}`} className='w-100 rounded' alt=''/>)
                                : <div dangerouslySetInnerHTML={{__html: (item.content) ? item.content : ""}} />
                        }
                    </div>
                    <div className='overlay-layer bg-dark bg-opacity-10'>
                        <a href="#"
                           className='btn btn-sm btn-primary shadow'
                           onClick={handleLocalStorage}
                        >
                            {item.name}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Template, TemplateDrawer}