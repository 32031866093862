import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Functions} from '../../../modules/all-pages'

interface Props extends chartOptionsProps {
    className: string
}

type chartOptionsProps = {
    chartOptions: {
        series: number[]
        categoriesXAxis: string[]
    }
}

const ChartDashboard4: React.FC<Props> = memo(({className, chartOptions}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, {chartOptions}))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, chartOptions])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">Nombre de visites</span>

                    {/*<span className='text-muted fw-bold fs-7'>More than 1000 new records</span>*/}
                </h3>
            </div>
            {/* end::Header */}

            {/* begin::Section1 */}
            <div className="card-body p-3">
                {/* begin::Chart */}
                <div ref={chartRef} id="kt_charts_widget_3_chart" style={{height: '350px'}}/>
                {/* end::Chart */}
            </div>
            {/* end::Section1 */}
        </div>
    )
})

export {ChartDashboard4}

function getChartOptions(height: number, {chartOptions}: chartOptionsProps): ApexOptions {

    const primary = getCSSVariableValue('--bs-primary')
    const success = getCSSVariableValue('--bs-success')
    const gray200 = getCSSVariableValue('--bs-gray-200')
    const gray500 = getCSSVariableValue('--bs-gray-500')

    return {
        series: [
            {
                name: 'Incomplete',
                data: chartOptions.series,
                // data: [65, 80, 80, 65, 65, 45, 80, 80, 70, 70, 90, 90, 80, 80, 80, 65, 65, 50],
            },
            // {
            //   name: 'Complete',
            //   data: [90, 110, 110, 90, 90, 80, 95, 95, 115, 115, 100, 100, 115, 115, 95, 95, 80, 80],
            // },
        ],
        chart: {
            // zoom: {
            //     enabled: false
            // },
            type: 'area',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: .5,
                opacityTo: .05,
                stops: [20, 100, 100, 100],
            },
        },
        // fill: {
        //     type: 'solid',
        //     opacity: 1
        // },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [primary, success],
        },

        xaxis: {
            categories: chartOptions.categoriesXAxis,
            axisBorder: {
                show: false,
            },
            tickAmount: 6,

            axisTicks: {
                show: false,
            },
            labels: {
                offsetX: 10,
                style: {
                    colors: gray500,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: primary,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: gray500,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' visits'
                },
            },
        },
        colors: [primary, success],
        grid: {
            borderColor: gray200,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            //size: 5,
            colors: [primary, success],
            strokeColors: [primary, success],
            strokeWidth: 3,
        },
    }
}
