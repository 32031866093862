import React, {FC, useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {KTSVG} from "../../../helpers";
import RedirectPages from "../../../../app/modules/functions/RedirectPages";

const HeaderUserMenu: FC = () => {

  const [redirect, setRedirect] = useState(true);

  useEffect( () => {

    const redirectTo = RedirectPages();
    if (!redirectTo) {
      setRedirect(false);
    }

  }, []);

  function signOut() {

    localStorage.clear();
    setRedirect(false);
  }

  if (!redirect) {
    return <Redirect to='/login' />
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >

      <div className='menu-item '>
        <Link to={'/pages/profile/edit-profile'} className='menu-link px-5'>
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/general/gen049.svg'} className='svg-icon-1' />
          </span>
          Informations Personelles
        </Link>
      </div>

      <div className='menu-item '>
        <Link to={'/pages/products'} className='menu-link px-5'>
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/ecommerce/ecm008.svg'} className='svg-icon-1' />
          </span>
          Produits
        </Link>
      </div>

      <div className='menu-item '>
        <Link to={'/pages/privacy-policy'} className='menu-link px-5'>
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/general/gen051.svg'} className='svg-icon-1' />
          </span>
          Politique de confidentialité
        </Link>
      </div>

      <div className='separator my-2'/>

      <div className='menu-item px-5'>
        <button onClick={signOut} className='btn btn-active-light-info text-info'>
          Déconnexion
        </button>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
