import React, {useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import RedirectPages from "../../modules/functions/RedirectPages";
import LoginPost from "../../modules/functions/post/LoginPost";
import SVG from "react-inlinesvg";
import {useHistory} from 'react-router-dom'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Format d\'e-mail incorrect')
        .required('E-mail est obligatoire'),
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(30, 'Maximum 30 symbols')
        .required('Mot de passe est obligatoire'),
})

const initialValues = {
    email: 'demo@webforce.be', // demo@webforce.be
    password: 'admin123456', // Pa$$w0rd!
}


function Login() {

    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [color, setColor] = useState('');
    const redirectTo = RedirectPages();

    useEffect( () => {

        if (redirectTo) {
            setRedirect(true);
        }
    }, []);

    const formik = useFormik({

      initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, {setStatus, setSubmitting}) => {

        setLoading(true);
        setTimeout(() => {

            let dataObject = {
                email: values.email,
                password: values.password,
            };

            LoginPost(
                dataObject,
                setRedirect,
                setLoading,
                setSubmitting,
                setStatus,
                '',
                'E-mail ou Mot de passe incorrect',
                setColor
            );
        }, 100);
      },
    });

    if (redirect) {
        return <Redirect to='/dashboard' />
    }

    return (

        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <div className='mb-10'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo-WF_2.png')} className='h-75px' />
                </div>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_login_sign_in_form'
                    >
                        {/* begin::Heading */}
                        <div className='text-center mb-10'>
                          <h1 className='text-dark mb-3'>Bienvenue sur MyWebforce</h1>
                        </div>
                        {/* end::Heading */}

                        {formik.status ? (
                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                <span className="svg-icon svg-icon-2hx svg-icon-danger me-2">
                                    <span className='svg-icon svg-icon-2hx svg-icon-danger me-4'>
                                        <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen050.svg')}/>
                                    </span>
                                </span>
                                <div className="d-flex flex-column">
                                    <span>{formik.status}</span>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* begin::E-mail */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
                            <input placeholder='Email'
                                {...formik.getFieldProps('email')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.email && formik.errors.email },
                                    { 'is-valid': formik.touched.email && !formik.errors.email }
                                )}
                                type='email'
                                name='email'
                                autoComplete='off'
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container my-1'>
                                <span role='alert' className="text-danger p-2">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>
                        {/* end::E-mail */}

                        {/* begin::Password */}
                        <div className='fv-row mb-10'>
                            <div className='d-flex justify-content-between mt-n5'>
                                <div className='d-flex flex-stack mb-2'>
                                    {/* begin::Label */}
                                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
                                    {/*end::Label*/}
                                </div>
                            </div>
                            <input
                                placeholder='Mot de passe'
                                type='password'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.password && formik.errors.password },
                                    { 'is-valid': formik.touched.password && !formik.errors.password }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container my-1'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className="text-danger p-2">{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex rs-flex-box-grid-end mt-2">
                                {/*begin::Link*/}
                                <Link to='/mot-de-passe-oublie'
                                    className='link-primary fs-6 fw-bolder'
                                >
                                    Mot de passe oublié ?
                                </Link>
                                {/*end::Link*/}
                            </div>
                        </div>
                        {/* end::Password */}

                        {/* begin::Action */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn btn-lg btn-primary w-100 mb-5'
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'>Se connecter</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                    </span>
                            )}
                            </button>
                        </div>
                        {/* end::Action */}
                    </form>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}

    </div>
    )
}
export default Login
