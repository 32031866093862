import {createContext} from "react";

export interface ProductReturn {
    name: string;
    status: number;
    date: string;
    description: string;
}

const ProductContext = createContext<ProductReturn[]>([]);

export {ProductContext}