import React, {ChangeEvent, FC} from 'react'
import {Functions} from '../../Functions'
import {PropsSelectItems} from '../../Types'

const SelectItems: FC<PropsSelectItems> = ({filterResults, setFilterResults, setCheckedAll, setIds, item}) => {

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {

        const {all, ids, selected} = Functions.HandleSelect({e, data: filterResults})

        if (all) setCheckedAll(true);
        else setCheckedAll(false);
        setFilterResults(selected);
        setIds(ids);
    }

    return (
        <>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input widget-9-check me-3'
                       type='checkbox'
                       value='1'
                       name={`${item.id}`}
                       checked={item?.isChecked || false}
                       onChange={handleSelect}
                />
            </div>
        </>
    )
}

export {SelectItems}