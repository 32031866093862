import React, {FC, useEffect, useState} from 'react'
import {CreateProduct} from '../../all-pages'
import {PropsProducts} from '../../../pages/products/TypesProducts'
import GetProducts from '../../functions/get/GetProducts'

const CreateAccountM: FC = () => {

    const [productsData, setProductsData] = useState<PropsProducts[]>([]);
    const account = "Mailing"

    useEffect(() => {

        GetProducts({setProductsData});
    }, []);

    return (
        <>
            {
                productsData.map((product, index) =>
                  (product.name === account)
                    ? (
                      <CreateProduct name={product.name}
                                     description={product.description}
                                     treaty={product.treaty}
                                     key={index}
                      />
                    ) : ""
                )
            }
        </>
    )
}
export {CreateAccountM}
