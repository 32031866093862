import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Login from '../pages/auth/Login';

export function PublicRoutes() {

    return (
        <Switch>
            <Route path='/' component={Login} />
            <Redirect to='/login' />
        </Switch>
    )
}
