import React from "react";

export default function SuccessCatch(
    setSubmitting: any,
    setStatus:any,
    msgSuccess: string,
    setColor: any,
) {

    if (setSubmitting) setSubmitting(true);
    if (setStatus) setStatus(msgSuccess);

    if (setColor !== null) { setColor('success'); }
}
