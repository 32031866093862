interface FormFieldInterface {
    label: string,
    touched?: boolean,
    error?: string,
    formikProps: any,
    type: 'text' | 'textarea',
    rows?: number
}

const FormField = ({label, touched, error, formikProps, type, rows = 1}: FormFieldInterface) => (
    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
        <div className="w-100 d-flex justify-content-between">
            <label className="required fs-6 fw-semibold mb-2">{label}</label>
            {touched && error && (
                <div className="fv-plugins-message-container my-1">
                    <span role="alert" className="text-danger p-2">{error}</span>
                </div>
            )}
        </div>
        {type === 'textarea' ? (
            <textarea {...formikProps} rows={rows} className="form-control form-control-solid" placeholder={label} />
        ) : (
            <input type={type} {...formikProps} className="form-control form-control-solid" placeholder={label} />
        )}
    </div>
)

export {FormField}