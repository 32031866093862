import React from "react";
import axios from "axios";
import ErrorCatch from "../../ErrorCatch";
import SuccessCatch from "../SuccessCatch";
import {API_URL} from '../../../all-pages'

type Props = {
    data: object,
    setSubmitting: any,
    setStatus: any,
    setColor: any,
    msgError: string,
    msgSuccess: string,
}

export default function EmailExist(
    {
        data,
        setSubmitting,
        setStatus,
        setColor,
        msgError,
        msgSuccess,
    }: Props
) {

    const FORGOT_PASSWORD = process.env.REACT_APP_FORGOT_PASSWORD;

    axios({

        method: 'POST',
        url: `${API_URL}${FORGOT_PASSWORD}`,
        headers: { },
        data: data
    })
        .then(function (response) {

            if (response.data.status === 200) {
                SuccessCatch(setSubmitting, setStatus, msgSuccess, setColor);
            }
            if (response.data.status === 500) {
                ErrorCatch(null, setSubmitting, setStatus, '', msgError, null, null, setColor);
            }

        })
        .catch(function (error) {

            console.log('OK => ', error)

            ErrorCatch(null, setSubmitting, setStatus, '', msgError, null, null, setColor);
        });
}

