import React, {useEffect, useReducer, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Action, PropsNumberRows, PropsPaginate, PropsPagination, State} from '../Types'
import {ActionType} from '../Variables'

function reducer(state: State, action: Action) {

    switch (action.type) {

        case ActionType.INCREMENT:
            return {count: state.count + 1}
        case ActionType.DECREMENT:
            return {count: state.count - 1}
        default:
            throw new Error('Unknown action')
    }
}

const initialState = {
    count: 1,
}

export const Pagination: React.FC<PropsPagination> = ({postsPerPage, totalPosts, setCurrentPage, setListsPerPage}) => {
    
    const {location} = useHistory()

    const {pathname} = location
    const path = ['/statistiques/s-seo'].includes(pathname)

    const [state, dispatch] = useReducer(reducer, initialState)
    const [numberR, setNumberR] = useState<number>(10)

    const pageNumber: number[] = []

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumber.push(i)
    }

    useEffect(() => {

        if (pageNumber.length && pageNumber.length < state.count) {

            state.count = pageNumber.length
            Paginate({pageNumber: state.count, setCurrentPage})
        }
    }, [numberR, pageNumber.length, setCurrentPage, state])


    const Paginate = ({pageNumber, setCurrentPage}: PropsPaginate) => {
        setCurrentPage(pageNumber)
    }

    const NumberRows = ({numberRows, setListsPerPage}: PropsNumberRows) => {
        setListsPerPage(numberRows)
    }

    return (
        <>
            {
                totalPosts >= 10
                    ? (
                        <div className="row d-flex justify-content-end align-items-center mt-3">
                            <div className={`${!path
                                ? 'col-5 col-sm-3 col-xl-2'
                                : 'col-5 col-sm-3 col-md-4'
                            } d-flex justify-content-end align-items-center`}
                            >
                                <select className="form-select form-select-sm form-select-solid "
                                        onChange={(e) => {
                                            setNumberR(parseInt(e.target.value))
                                            NumberRows({numberRows: parseInt(e.target.value), setListsPerPage})
                                        }}
                                >
                                    <option value={'10'}>10</option>
                                    <option value={'20'}>20</option>
                                    <option value={'50'}>50</option>
                                </select>
                            </div>
                            <div className={`${!path
                                ? 'col-5 col-sm-3 col-xl-2'
                                : 'col-7 col-sm-3 col-md-8'
                            } d-flex justify-content-end align-items-center`}
                            >
                                <ul className='pagination'>
                                    <li className='page-item previous'>
                                        <button
                                            className={`page-link ${(state.count === 1) ? 'invisible' : ''}`}
                                            onClick={() => {
                                                dispatch({type: ActionType.DECREMENT})
                                                Paginate({pageNumber: state.count - 1, setCurrentPage})
                                            }}
                                        >
                                            <i className='previous'/>
                                        </button>
                                    </li>
                                    <li className='page-item active'>
                            <span className='page-link'>
                                {state.count}
                            </span>
                                    </li>
                                    <li className='page-item next'>
                                        <button
                                            className={`page-link ${(pageNumber.length <= state.count || !pageNumber.length) ? 'invisible' : ''}`}
                                            onClick={() => {
                                                dispatch({type: ActionType.INCREMENT})
                                                Paginate({pageNumber: state.count + 1, setCurrentPage})
                                            }}
                                        >
                                            <i className='next'/>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                    : ''
            }
        </>
    )
}