export function stripHtml(html: string): string {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
}

export function truncateText(text: string, n: number): string {
    if (text.length > n) {
        return text.slice(0, n) + '...';
    }
    return text;
}