import React from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {Error500} from './components/Error500';
import {Error404} from './components/Error404';

const ErrorsPage: React.FC = () => {

    return (
        <Switch>

            <Route path='/error/404' exact={true}>
                <Error404 />
            </Route>
            <Route path='/error/500' exact={true}>
                <Error500 />
            </Route>

            <Redirect from='/error' exact={true} to='/error/404' />
            <Redirect to='/error/404' />

        </Switch>
    )
}

export {ErrorsPage}
