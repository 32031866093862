import React, {createContext, useContext, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import SVG from 'react-inlinesvg'
import {RequestAProduct} from '../../modules/functions/post'
import axios from 'axios'
import {API_URL, Card, localStorageValue, Modal} from '../../modules/all-pages'
import './styles.css'

const ProductsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Products',
        path: '/pages/products',
        isSeparator: false,
        isActive: false,
    }, {
        title: '',
        path: '',
        isSeparator: false,
        isActive: false,
    },
]


export const ProductContext = createContext<PropsContext>({
    productRequest: [],
    products: {
        name: "",
        date: "",
        description: "",
        status: 0,
        treaty: 0,

    }
});


// type productRequests = {
//     Agenda: PropsProductRequest;
//     Calltraking: PropsProductRequest;
//     Ereputaion: PropsProductRequest;
//     Mailing: PropsProductRequest;
//     Statistiques: PropsProductRequest;
//     Webformulaires: PropsProductRequest;
// }

type listOfProducts = {
    id: number;
    name: string;
    description: string;
}


type PropsProducts = {
    name: string;
    date: string;
    description: string;
    status: number;
    treaty: number;
}

type PropsProductRequest = {
    id: number;
    products: string;
    trait: number
}

type PropsContext = {
    productRequest: string[];
    products: PropsProducts;
}

const Products: React.FC = () => {

    const [productsData, setProductsData] = useState<PropsProducts[]>([]);
    const GET_PRODUCTS = process.env.REACT_APP_GET_PRODUCTS;
    const [keysProductsRequest, setKeyProductsRequest] = useState<string[]>([]);


    useEffect(() => {

        axios({

            method: "GET",
            url: `${API_URL}${GET_PRODUCTS}`,
            headers: {
                'Authorization': `Bearer ${localStorageValue}`,
            },
        })
            .then((response) => {

                const DATA = response.data;
                const productRequest = DATA.demandeProducts,
                    products = DATA.products,
                    listOfProducts: listOfProducts[] = DATA.products_list;

                const values: PropsProductRequest[] = Object.values(productRequest)

                let products1: PropsProducts[] = [];
                const keys: string[] = [];

               listOfProducts.forEach((product) => {

                   let status: number = products[`${product.name}`];
                   let date: string = products[`${product.name}Date`];
                   let name: string = product.name;
                   let description: string = product.description;
                   let treaty: number = 0;

                   values.forEach((i) => {
                       if (i.products == product.name) {
                           name = i.products;
                           treaty = i.trait;
                           keys.push(product.name);
                       }
                   });

                   if (name == "Ereputaion") name = "E-réputation"

                   products1.push({status, date, name, description, treaty})
               })

                products1 = products1.filter((i) => !(i.name.toLowerCase() === 'agenda'))

                setProductsData(products1);
                setKeyProductsRequest(keys);

            })
            .catch((error) => {

                console.log(error);
            });

    }, []);

    return (
        <>
            <Card id={"products"} title={"Produits"} >
                {/* begin::Item */}
                {
                    productsData.map((product, index) => (

                        <ProductContext.Provider value={{
                            productRequest: keysProductsRequest,
                            products: product
                        }} key={index}>
                            <Item />
                        </ProductContext.Provider>
                    ))
                }
                {/* end:Item */}
            </Card>
        </>
    )

}

/**
 * Item
 */
const Item: React.FC = () => {

    const productContext = useContext(ProductContext);

    const product = productContext.products;
    // const productRequest = productContext.productRequest
    const status = (product.status === 1) ? "actif" : "inactif";
    const date = (product.status === 1) ? product.date : "";

    return (

        <div className='row mb-8'>
            <PageTitle breadcrumbs={ProductsBreadCrumbs} />
            <div className="col-xl-6 col-sm-5 col-9  d-flex align-items-center">
                <span className={`bullet bullet-vertical h-40px me-7 bg-${(status === 'actif' ? 'success' : 'danger')}`} />
                <span className='text-gray-800 fw-bolder fs-6 align-items-center text-capitalize'>
                    {product.name}
                </span>
            </div>
            <div className="col-xl-2 col-sm-2 col-3 d-flex align-items-center justify-content-sm-center justify-content-end">
                <span className={`badge fs-8 fw-bolder text-capitalize badge-light-${(status === 'actif' ? 'success': 'danger')}`}>{status}</span>
            </div>
            <div className="col-xl-3 col-sm-5 col-9 offset-xl-0 offset-sm-0 offset-3 d-flex justify-content-end align-items-center">
                {
                    date ? product.date : <ModalProduct />
                }
            </div>
        </div>
    )
}

/**
 * @type PropsModal
 */
const ModalProduct: React.FC= () => {

    const productContext = useContext(ProductContext);
    const product = productContext.products;
    const productRequest = productContext.productRequest;

    const [loading, setLoading] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [treaty1, setTreaty1] = useState<boolean>(false);

    let productName = product.name.toLowerCase();
    let productId = 'kt_modal_' + productName;
    const initialValues = {
        message: product.name,
        products: product.name,
    }
    const formik = useFormik({

        initialValues,
        onSubmit: (values, {setStatus, setSubmitting}) => {

            setLoading(true);
            setSubmitting(false);

            setTimeout(() => {

                RequestAProduct({values, setStatus, setLoading, setDisable, setTreaty1})
            }, 300)
        },
    });

    return (

        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            {/* components 1:: start */}
            {
                productRequest.includes(product.name)
                    ?
                        (product.treaty === 0 || product.treaty === 1 || treaty1)
                            ? (

                                <div className="d-flex justify-content-between align-items-center fs-7 text-info fw-bolder demandIcon"
                                >
                                    <div className={""}>Traitement en cours</div>
                                    <KTSVG path='/media/icons/duotune/abstract/abs014.svg'
                                           className='svg-icon-1 svg-icon-info mx-2'
                                    />
                                </div>
                            )
                            : (
                                <button type="button"
                                        className="btn btn-sm btn-flex btn-light btn-active-light-primary fw-bolder"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#kt_modal_${productId}`}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen044.svg'
                                           className='svg-icon-1 svg-icon-gray-500 me-1'
                                    />
                                    Demande de devis
                                </button>
                            )
                            : (
                        <button type="button"
                                className="btn btn-sm btn-flex btn-light btn-active-light-primary fw-bolder"
                                data-bs-toggle="modal"
                                data-bs-target={`#kt_modal_${productId}`}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen044.svg'
                                   className='svg-icon-1 svg-icon-gray-500 me-1'
                            />
                            Demande de devis
                        </button>
                    )

            }

            <Modal id={`${productId}`}
                   title={`${product.name}`}
                   media="xl"
                   body={
                       <>
                           {formik.status ? (
                               <div className={`alert alert-success d-flex align-items-center p-2 mb-7`}>
                                <span className={`svg-icon svg-icon-2hx svg-icon-success mx-2`}>
                                    <span className={`svg-icon svg-icon-2hx svg-icon-success me-4`}>
                                         <SVG src={toAbsoluteUrl(`/media/icons/duotune/general/gen048.svg`)}/>
                                    </span>
                                </span>
                                   <div className="d-flex flex-column">
                                       <span>{formik.status}</span>
                                   </div>
                               </div>
                           ) : (
                               ''
                           )}
                           <div className='row mb-8'>
                               <div dangerouslySetInnerHTML={{__html: product.description}}/>
                           </div>
                       </>
                   }
                   footer={
                       <>
                           <button type='submit'
                                   className='btn btn-sm btn-flex btn-light-primary btn-active-light-primary fw-bolder'
                                   disabled={disable}
                           >
                               {!loading && (
                                   <>
                                       <KTSVG path='/media/icons/duotune/general/gen016.svg'
                                              className='svg-icon-2 svg-icon-gray-500 me-2'
                                       />
                                       Demande
                                   </>
                               )}
                               {loading && (
                                   <span className='indicator-progress' style={{display: 'block'}}>
                                    S'il vous plaît, attendez...{' '}
                                       <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                </span>
                               )}
                           </button>
                       </>
                   }
            />

            {/*<div className="modal fade" tabIndex={-1} id={`kt_modal_${productId}`}>*/}
            {/*    <div className="modal-dialog modal-xl">*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-header">*/}
            {/*                <h5 className="modal-title">{product.name}</h5>*/}
            {/*                <div*/}
            {/*                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"*/}
            {/*                  data-bs-dismiss="modal"*/}
            {/*                  aria-label="Close"*/}
            {/*                >*/}
            {/*                    <KTSVG*/}
            {/*                      path="/media/icons/duotune/arrows/arr061.svg"*/}
            {/*                      className="svg-icon svg-icon-2x"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="modal-body">*/}
            {/*                {formik.status ? (*/}
            {/*                    <div className={`alert alert-success d-flex align-items-center p-2 mb-7`}>*/}
            {/*                        <span className={`svg-icon svg-icon-2hx svg-icon-success mx-2`}>*/}
            {/*                            <span className={`svg-icon svg-icon-2hx svg-icon-success me-4`}>*/}
            {/*                                 <SVG src={toAbsoluteUrl(`/media/icons/duotune/general/gen048.svg`)}/>*/}
            {/*                            </span>*/}
            {/*                        </span>*/}
            {/*                        <div className="d-flex flex-column">*/}
            {/*                            <span>{formik.status}</span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                ) : (*/}
            {/*                  ''*/}
            {/*                )}*/}
            {/*                <div className='row mb-8'>*/}
            {/*                    <div dangerouslySetInnerHTML={{__html: product.description}} />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="modal-footer">*/}
            {/*                <button type='submit' className='btn btn-sm btn-flex btn-light-primary btn-active-light-primary fw-bolder'*/}
            {/*                        disabled={disable}*/}
            {/*                >*/}
            {/*                    {!loading && (*/}
            {/*                      <>*/}
            {/*                          <KTSVG path='/media/icons/duotune/general/gen016.svg'*/}
            {/*                                 className='svg-icon-2 svg-icon-gray-500 me-2'*/}
            {/*                          />*/}
            {/*                          Demande*/}
            {/*                      </>*/}
            {/*                    )}*/}
            {/*                    {loading && (*/}
            {/*                        <span className='indicator-progress' style={{display: 'block'}}>*/}
            {/*                            S'il vous plaît, attendez...{' '}*/}
            {/*                            <span className='spinner-border spinner-border-sm align-middle ms-2'/>*/}
            {/*                        </span>*/}
            {/*                    )}*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


        </form>
    )
}

export {Products}