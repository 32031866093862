import React, {FC} from 'react'
import {useHistory} from "react-router-dom";
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
    to: string,
    page: string
}
const Error: FC<Props> = ({to, page}) => {

    const history = useHistory()
    const redirectToMailing = () => {

        history.push(`${to}`)
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div
                    className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                >
                    <div className='d-flex flex-column flex-column-fluid text-center'>
                        <div className="mb-10">

                            <div className='fw-bold fs-3 text-gray-400 mb-15'>
                                La page demandée n'a pas été trouvée ! <br />
                            </div>

                            <div className='text-center'>
                                <a onClick={redirectToMailing} className='btn btn-lg btn-primary fw-bolder'>
                                    Aller à la page d'accueil {page}
                                </a>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
                            style={{
                                backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sigma-1/18-dark.png')}')`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export {Error}
