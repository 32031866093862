import fr_FR from 'rsuite/locales/fr_FR';

import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'

const rotate_0 = {fontFamily: "inherit", transform: "rotate(0)", transition: "all .3s ease-in-out",},
    rotate_90 = {fontFamily: "inherit", transform: "rotate(90deg)", transition: "all .3s ease-in-out",},
    rotate_180 = {fontFamily: "inherit", transform: "rotate(180deg)", transition: "all .3s ease-in-out",},
    API_URL = process.env.REACT_APP_API_URL,
    URL = process.env.REACT_APP_URL,
    localStorageKey = '_token',
    localStorageKeyAuth = '_auth',
    localStorageKeyTemplateSelected = '_templateSelected',
    localStorageKeyTemplateSelectedData = '_templateSelectedData',
    localStorageValue = window.localStorage.getItem(localStorageKey),
    localStorageValueAuth = window.localStorage.getItem(localStorageKeyAuth);

enum ActionType {
    INCREMENT = "INCREMENT",
    DECREMENT = "DECREMENT"
}

const LocalesRangeValue = [{ key: 'fr_FR', value: fr_FR },];


const success = "#50cd89",
    primary = "#009ef7",
    warning = "#ffc700",
    danger = "#f1416c",
    info = "#7239ea",
    dark = "#7239ea";

const labelColor = getCSSVariableValue('--bs-gray-500'),
    borderColor = getCSSVariableValue('--bs-gray-200'),
    lightColor = getCSSVariableValue('--bs-light');

const primaryColor = getCSSVariableValue('--bs-' + "primary"),
    secondaryColor = getCSSVariableValue('--bs-secondary'),
    successColor = getCSSVariableValue('--bs-success'),
    infoColor = getCSSVariableValue('--bs-info'),
    warningColor = getCSSVariableValue('--bs-warning'),
    dangerColor = getCSSVariableValue('--bs-danger'),
    darkColor = getCSSVariableValue('--bs-dark');

const primaryLightColor = getCSSVariableValue('--bs-light-primary'),
    secondaryLightColor = getCSSVariableValue('--bs-light-secondary'),
    successLightColor = getCSSVariableValue('--bs-light-success'),
    infoLightColor = getCSSVariableValue('--bs-light-info'),
    warningLightColor = getCSSVariableValue('--bs-light-warning'),
    dangerLightColor = getCSSVariableValue('--bs-light-danger'),
    darkLightColor = getCSSVariableValue('--bs-light-dark');


const strokeColor = getCSSVariableValue('--bs-gray-300');

export {
    URL,
    API_URL,
    localStorageKey,
    localStorageKeyAuth,
    localStorageKeyTemplateSelected,
    localStorageKeyTemplateSelectedData,
    localStorageValue,
    localStorageValueAuth,
    ActionType,
    rotate_0,
    rotate_90,
    rotate_180,
    LocalesRangeValue,
    success,
    primary,
    warning,
    danger,
    info,
    dark,
    labelColor,
    borderColor,
    lightColor,
    primaryColor,
    secondaryColor,
    successColor,
    infoColor,
    warningColor,
    dangerColor,
    darkColor,
    primaryLightColor,
    secondaryLightColor,
    successLightColor,
    infoLightColor,
    warningLightColor,
    dangerLightColor,
    darkLightColor,
    strokeColor,
}