import SetupAxios from '../../../../setup/axios/SetupAxios'
import {API_URL} from '../../all-pages'
import {PeriodType} from '../../../../global'

interface GetInterface<T> {
    endpoint: string
    period?: PeriodType
    sortKey?: string
    sortOrder?: string
}

export async function Get<T>({endpoint, period, sortKey, sortOrder}: GetInterface<T>) {

    const start = period?.start;
    const end = period?.end;

    try {
        const params = new URLSearchParams({
            ...(period && {start, end}),
            ...(sortKey && {sortf: sortKey}),
            ...(sortOrder && {sorts: sortOrder})
        }).toString()

        const response = await SetupAxios.get<T>(`${API_URL}${endpoint}?${params}`)
        return response.data
    } catch (e) {

        console.error('Error fetching call log data:', e);
    }
}