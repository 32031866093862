import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import SetupAxios from '../../../setup/axios/SetupAxios'
import {IdParams} from '../../../_metronic/partials/widgets/tables/Mailing/settings/contacts/Types'
import {
    ApiTicketResponse,
    HandleFileChange,
    HandleFileClick, initialValuesTicket,
    Ticket as TicketType, Ticket_Status,
} from './types'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {NotificationsContext, useUser} from '../../modules/context/types'
import {useSavContext} from './context/SavContext'
import {Responses} from './Responses'
import {Get} from '../../modules/functions/get/Get'
import {SatisfactionTicketModal} from './components'

const TICKETS_DETAILS = process.env.REACT_APP_SAV_TICKETS_DETAILS || 'sav/detail-ticket/'
const SEND_MESSAGE = process.env.REACT_APP_SAV_TICKETS_FORM_SEND_MESSAGE || 'sav/save-ticket'

const fetchData = async (endpoint: string): Promise<TicketType | undefined> => {
    const response = await Get<ApiTicketResponse>({endpoint})

    return response?.ticket
}
const postData = async (formData: FormData): Promise<any> => {
    const response = await SetupAxios.post(SEND_MESSAGE, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
    })
    return response.data
}

const validationSchema = Yup.object().shape({
    message: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(500, 'Maximum 500 symbols')
        .required('Obligatoire'),
    files: Yup.mixed(),
})

const initialValues = {
    message: '',
    files: [] as File[],
}
const Ticket = () => {

    const {id: ticketId}: IdParams = useParams()
    const {id: userId} = useUser()
    const {data} = useSavContext()
    const [ticket, setTicket] = useState<TicketType>(initialValuesTicket)
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const [filesCount, setFilesCount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [ticketStatus, setTicketStatus] = useState<Ticket_Status>({id: -1, color: '', name: ''})
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const {refreshNotifications} = useContext(NotificationsContext)

    const endpoint = useMemo(() => `${TICKETS_DETAILS}${ticketId}`, [ticketId])

    useQuery(['ticket', ticketId], () => fetchData(endpoint), {
        onSuccess: (data) => {
            if (data) setTicket(data)
        },
    })

    useEffect(() => {
        refreshNotifications()
        if (data) {
            const status = data.statusArray?.find(status => status.name === ticket.status)
            setTicketStatus(status ?? {id: -1, color: '', name: ''})
        }
    }, [data, ticket.status])

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {resetForm, setSubmitting, setStatus}) => {

            setLoading(true)
            setSubmitting(false)
            const formData = new FormData()
            formData.append('client_id', `${userId}`)
            formData.append('sav_ticket_id', ticketId)
            formData.append('message', values.message)

            values.files.forEach(file => formData.append('files[]', file))

            try {

                const response = await postData(formData)
                if (response.status === 200) {
                    setLoading(false)
                    setFilesCount(0)
                    setRefreshKey(prev => prev + 1)
                    setTimeout(() => resetForm(), 300)
                    setTimeout(() => scrollToEndOfListResponse(), 900)
                }
            } catch (error) {
                console.error('Error posting data:', error)
                throw error
            }
        },
    })

    const endOfListRef = useRef<HTMLDivElement>(null)

    const scrollToEndOfListResponse = () => {
        if (endOfListRef.current) {
            endOfListRef.current.scrollTop = endOfListRef.current.scrollHeight
        }
    }

    useEffect(() => {
        scrollToEndOfListResponse()
    }, [data, loading])

    return (
        <>
            <SatisfactionTicketModal status={ticketStatus.name} satisfaction={ticket.satisfaction} />

            <div className="card w-100 rounded-0">
                <div className="card-header py-5">
                    <div className="card-title flex-column">
                        <div className="fw-bolder mb-2 fs-5 fs-sm-3">{ticket.subject}</div>
                        <div className="fs-6 mt-2">
                            <span className="fw-semibold text-gray-600">Services: <span
                                className="text-muted ms-1 me-3">{ticket.product}</span>
                            </span>
                            <span className="fw-semibold text-gray-600">Détails: <span
                                className="text-muted ms-1 me-3">{ticket.assign}</span>
                            </span>
                            <span className="fw-semibold text-gray-600">Créé le: <span
                                className="text-muted ms-1 me-3">{ticket.created_at}</span>
                            </span>
                        </div>
                    </div>
                    <div className="card-toolbar d-flex justify-content-between align-items-end flex-column">
                        <div className="rating d-flex justify-content-center align-items-center mb-5">

                            {ticket.satisfaction?.rating ? (
                                [...Array(5)].map((_, index) => (
                                    <div
                                        className={`rating-label me-2 ${index + 1 <= +ticket.satisfaction!.rating ? "checked" : ""}`}
                                        key={index}
                                    >
                                        <i className="bi bi-star-fill fs-5"></i>
                                    </div>
                                ))
                            ) : <></>}

                        </div>
                        <div className={`badge badge-light-${ticketStatus.color} badge-lg`}>{ticketStatus.name}</div>
                    </div>
                </div>
                <div ref={endOfListRef} className="card-body h-550px overflow-scroll">
                    <Responses ticketId={ticketId} refreshKey={refreshKey} />
                </div>
                {
                    ticketStatus.name !== 'Fermé' && ticketStatus.name !== '' && (
                        <div className="card-footer" id="kt_drawer_response_ticket_footer">
                            <form onSubmit={formik.handleSubmit}>
                                <textarea className="form-control form-control-solid mb-3"
                                          rows={2}
                                          data-kt-element="input"
                                          placeholder="Type a message"
                                          {...formik.getFieldProps('message')}
                                />
                                {formik.touched.message && formik.errors.message && (
                                    <div className="fv-plugins-message-container my-1 w-100">
                                        <div className="fv-help-block">
                                            <span role="alert" className="text-danger p-2">{formik.errors.message}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex flex-stack">
                                    <div className="d-flex align-items-center me-2">
                                        <input type="file"
                                               ref={fileInputRef}
                                               onChange={(e) => HandleFileChange({e, formik, setFilesCount})}
                                               accept=".png, .jpg, .jpeg, .pdf, .docx, .csv"
                                               multiple
                                               style={{display: 'none'}}
                                        />
                                        <button className="btn btn-sm btn-icon btn-active-light-primary me-1"
                                                type="button"
                                                onClick={() => HandleFileClick({fileInputRef})}

                                        >
                                            <i className="bi bi-paperclip fs-3"></i>
                                        </button>
                                        <div className="">
                                <span className="fw-bold fs-6 text-gray-900 px-5">
                                    {filesCount > 0 ? `${filesCount} fichiers` : ''}
                                </span>
                                        </div>
                                    </div>

                                    <button type="submit"
                                            className="btn btn-primary"
                                            disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className="indicator-label">Envoyer</span>}
                                        {loading && (
                                            <span className="indicator-progress" style={{display: 'block'}}>
                                        S'il vous plaît, attendez...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default Ticket